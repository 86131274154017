import React, { useEffect, useState, useCallback } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaEdit, FaTrashAlt, FaPlus, FaSave, FaTimes, FaArrowLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { getEmployees, createEmployee, updateEmployee, deleteEmployee, checkAdmin } from '../services/api';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';
import './EmployeeList.css';

const EmployeeList = () => {
    const navigate = useNavigate();
    const [employees, setEmployees] = useState([]);
    const [positionOptions, setPositionOptions] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [selectedPosition, setSelectedPosition] = useState([]);
    const [selectedDepartment, setSelectedDepartment] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentEmployee, setCurrentEmployee] = useState(null);
    const [formValues, setFormValues] = useState({
        name: '',
        gmail: '',
        contact: '',
        birth_date: '',
        position: '',
        department: '',
        hire_date: '',
        termination_date: '',
    });

    useEffect(() => {
        const checkAdminStatus = async () => {
            try {
                const response = await checkAdmin();
                const isAdmin = response.data.is_admin;
                if (!isAdmin) {
                    navigate('/'); // 如果不是管理員，重定向到首頁
                    return;
                }
                fetchData(); // 如果是管理員，則獲取數據
            } catch (error) {
                console.error('Error checking admin status:', error);
                navigate('/'); // 如果出錯，也重定向到首頁
            }
        };

        checkAdminStatus();
    }, [navigate]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (currentEmployee) {
            setFormValues({
                name: currentEmployee.name || '',
                gmail: currentEmployee.gmail || '',
                contact: currentEmployee.contact || '',
                birth_date: currentEmployee.birth_date ? formatDate(currentEmployee.birth_date) : '',
                position: currentEmployee.position || '',
                department: currentEmployee.department || '',
                hire_date: currentEmployee.hire_date ? formatDate(currentEmployee.hire_date) : '',
                termination_date: currentEmployee.termination_date ? formatDate(currentEmployee.termination_date) : '',
            });
        } else {
            setFormValues({
                name: '',
                gmail: '',
                contact: '',
                birth_date: '',
                position: '',
                department: '',
                hire_date: '',
                termination_date: '',
            });
        }
    }, [currentEmployee]);

    const fetchData = () => {
        getEmployees().then(response => {
            setEmployees(response.data);

            const uniquePositions = [...new Set(response.data.map(emp => emp.position))];
            const uniqueDepartments = [...new Set(response.data.map(emp => emp.department))];

            setPositionOptions(uniquePositions.map(position => ({ value: position, label: position })));
            setDepartmentOptions(uniqueDepartments.map(department => ({ value: department, label: department })));
        });
    };

    const data = React.useMemo(() => {
        let filteredData = employees;

        const filterByDateRange = (emp, dateField) => {
            const date = new Date(emp[dateField]);
            return date >= startDate && date <= endDate;
        };

        if (selectedPosition.length > 0) {
            filteredData = filteredData.filter(emp => selectedPosition.some(pos => pos.value === emp.position));
        }

        if (selectedDepartment.length > 0) {
            filteredData = filteredData.filter(emp => selectedDepartment.some(dep => dep.value === emp.department));
        }

        if (startDate && endDate) {
            filteredData = filteredData.filter(emp =>
                filterByDateRange(emp, 'hire_date') ||
                filterByDateRange(emp, 'termination_date')
            );
        }

        return filteredData;
    }, [employees, selectedPosition, selectedDepartment, startDate, endDate]);

    const handleAdd = () => {
        setCurrentEmployee(null);
        setIsEditing(false);
        setFormValues({
            name: '',
            gmail: '',
            contact: '',
            birth_date: '',
            position: '',
            department: '',
            hire_date: '',
            termination_date: '',
        });
        setIsFormVisible(true);
    };

    const handleEdit = (employee) => {
        setCurrentEmployee(employee);
        setIsEditing(true);
        setIsFormVisible(true);
    };

    const handleDelete = useCallback((employee) => {
        swal({
            title: "Delete Confirmation",
            text: `Are you sure you want to delete ${employee.name}?`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    deleteEmployee(employee.id).then(() => {
                        fetchData();
                        swal("Employee deleted successfully!", {
                            icon: "success",
                        });
                    });
                }
            });
    }, []);

    const columns = React.useMemo(() => [
        {
            Header: 'Index',
            accessor: (row, i) => i + 1,
        },
        {
            Header: '員工姓名',
            accessor: 'name',
        },
        {
            Header: '職位',
            accessor: 'position',
        },
        {
            Header: '部門',
            accessor: 'department',
        },
        {
            Header: '入職日期',
            accessor: 'hire_date',
        },
        {
            Header: '離職日期',
            accessor: 'termination_date',
            Cell: ({ value }) => (
                <span className="termination-date">{value}</span>
            ),
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                <div className="action-buttons">
                    <FaEdit onClick={() => handleEdit(row.original)} className="icon edit-icon" />
                    <FaTrashAlt onClick={() => handleDelete(row.original)} className="icon delete-icon" />
                </div>
            ),
        },
    ], [handleDelete]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        setGlobalFilter,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { globalFilter, pageIndex } = state;

    const formatDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        const form = event.target;

        const newEmployee = {
            name: form.name?.value || '',
            gmail: form.gmail?.value || '',
            contact: form.contact?.value || '',
            birth_date: form.birth_date?.value ? formatDate(form.birth_date.value) : null,
            position: form.position?.value || '',
            department: form.department?.value || '',
            hire_date: form.hire_date?.value ? formatDate(form.hire_date.value) : '',
            termination_date: form.termination_date?.value ? formatDate(form.termination_date.value) : null,
        };

        if (isEditing) {
            updateEmployee(currentEmployee.id, newEmployee).then(() => {
                fetchData();
                setIsFormVisible(false);
                setIsEditing(false);
                setCurrentEmployee(null);
            });
        } else {
            createEmployee(newEmployee).then(() => {
                fetchData();
                setIsFormVisible(false);
            });
        }
    };

    const handleClose = () => {
        setIsFormVisible(false);
    };

    return (
        <div className="container theme-blue">
            <div className="header">
                <Link to="/dashboard" className="back-button" title="返回 Dashboard">
                    <FaArrowLeft />
                </Link>
                <h1>員工管理</h1>
            </div>
            {isFormVisible && <div className="overlay visible" onClick={handleClose}></div>}
            <div className={`modal ${isFormVisible ? 'visible' : ''}`}>
                <div className="form-container">
                    <h2>{isEditing ? '編輯員工' : '增加員工'}</h2>
                    <form onSubmit={handleFormSubmit} className="employee-form">
                        <div className="form-group">
                            <label>
                                員工姓名: <span className="required">*</span>
                                <input type="text" name="name" value={formValues.name} onChange={(e) => setFormValues({ ...formValues, name: e.target.value })} required />
                            </label>
                            <label>
                                職位: <span className="required">*</span>
                                <input type="text" name="position" value={formValues.position} onChange={(e) => setFormValues({ ...formValues, position: e.target.value })} required />
                            </label>
                            <label>
                                部門: <span className="required">*</span>
                                <input type="text" name="department" value={formValues.department} onChange={(e) => setFormValues({ ...formValues, department: e.target.value })} required />
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                公司 Gmail: <span className="required">*</span>
                                <input type="text" name="gmail" value={formValues.gmail} onChange={(e) => setFormValues({ ...formValues, gmail: e.target.value })} required />
                            </label>
                            <label>
                                聯絡方式:
                                <input type="text" name="contact" value={formValues.contact} onChange={(e) => setFormValues({ ...formValues, contact: e.target.value })} />
                            </label>
                            <label>
                                生日日期:
                                <input type="date" name="birth_date" value={formValues.birth_date} onChange={(e) => setFormValues({ ...formValues, birth_date: e.target.value })} />
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                入職日期: <span className="required">*</span>
                                <input type="date" name="hire_date" value={formValues.hire_date} onChange={(e) => setFormValues({ ...formValues, hire_date: e.target.value })} required />
                            </label>
                            <label>
                                離職日期:
                                <input type="date" name="termination_date" value={formValues.termination_date} onChange={(e) => setFormValues({ ...formValues, termination_date: e.target.value })} />
                            </label>
                        </div>
                        <div className="form-actions">
                            <button type="button" className="icon-button cancel-button" onClick={handleClose}>
                                <FaTimes className="icon" /> 取消
                            </button>
                            <button type="submit" className="icon-button save-button">
                                <FaSave className="icon" /> 儲存
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {
                <div>
                    <div className="filters">
                        <input
                            value={globalFilter || ''}
                            onChange={e => setGlobalFilter(e.target.value || undefined)}
                            placeholder="搜尋..."
                        />
                        <Select
                            options={positionOptions}
                            value={selectedPosition}
                            onChange={setSelectedPosition}
                            placeholder="選擇職位"
                            isClearable
                            isMulti
                        />
                        <Select
                            options={departmentOptions}
                            value={selectedDepartment}
                            onChange={setSelectedDepartment}
                            placeholder="選擇部門"
                            isClearable
                            isMulti
                        />
                        <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="開始日期"
                            isClearable
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            placeholderText="結束日期"
                            isClearable
                        />
                        <button className="add-button" onClick={handleAdd}>
                            <FaPlus className="icon" /> 增加員工
                        </button>
                    </div>
                    <table {...getTableProps()} className="table">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span style={{ marginLeft: '10px' }}>
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? <i className="fas fa-sort-down"></i>
                                                        : <i className="fas fa-sort-up"></i>
                                                    : null}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => (
                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                            className="pagination-button"
                        >
                            <FaAngleDoubleLeft />
                        </button>
                        <button
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                            className="pagination-button"
                        >
                            <FaAngleLeft />
                        </button>
                        <span className="pagination-info">
                            {pageIndex + 1} / {pageOptions.length}
                        </span>
                        <button
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                            className="pagination-button"
                        >
                            <FaAngleRight />
                        </button>
                        <button
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                            className="pagination-button"
                        >
                            <FaAngleDoubleRight />
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

export default EmployeeList;