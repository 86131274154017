import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { adminLogin, googleLogin } from '../services/api';
import { ReactComponent as GoogleLogo } from '../assets/google.svg';
import swal from 'sweetalert';
import './Login.css';
<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap" rel="stylesheet"></link>

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const googleLoginProcessed = useRef(false);

  const handleAdminLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await adminLogin(username, password);
      const accessToken = response.data.access;
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('is_admin', 'true');
      navigate('/dashboard');
    } catch (error) {
      console.error('Admin login failed', error);
      swal("Login Failed", "No active account found with the given credentials", "error");
    }
  };

  const handleGoogleLogin = () => {
    const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const googleRedirectUri = process.env.REACT_APP_ROOT_URL;

    console.log('Google Client ID:', googleClientId);
    console.log('Google Redirect URI:', googleRedirectUri);

    const encodedRedirectUri = encodeURIComponent(googleRedirectUri);
    const googlePrompt = 'consent';
    const googleResponseType = 'code';
    const googleScope = 'openid email profile';
    const googleAccessType = 'offline';
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleClientId}&redirect_uri=${encodedRedirectUri}&prompt=${googlePrompt}&response_type=${googleResponseType}&scope=${googleScope}&access_type=${googleAccessType}`;

    console.log('Google Auth URL:', googleAuthUrl);

    if (!googleClientId || !googleRedirectUri) {
      console.error('Google Client ID or Redirect URI is missing');
      swal("Configuration Error", "Google login is not properly configured", "error");
      return;
    }

    window.location.href = googleAuthUrl;
  };

  const handleGoogleCallback = async (code) => {
    if (googleLoginProcessed.current) return;
    googleLoginProcessed.current = true;

    try {
      const response = await googleLogin(code);
      const accessToken = response.data.access;
      localStorage.setItem('access_token', accessToken);
      localStorage.setItem('is_admin', 'false');
      window.history.replaceState({}, document.title, window.location.pathname);
      navigate('/dashboard');
    } catch (error) {
      console.error('Google login failed', error);
      googleLoginProcessed.current = false;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      handleGoogleCallback(code);
    }
  }, []);

  return (
    <div className="login-body">
      <div className="background-shapes">
        <div className="shape shape-1"></div>
        <div className="shape shape-2"></div>
        <div className="shape shape-3"></div>
        <div className="shape shape-4"></div>
        <div className="shape shape-5"></div>
        <div className="shape shape-6"></div>
      </div>
      <div className="login-container">
        <h1 className="login-title">WisdomeAI HR 管理系統</h1>
        <div>
          <form className="login-form" onSubmit={handleAdminLogin}>
            <input
              className="login-input"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              required
            />
            <input
              className="login-input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <button className="login-button" type="submit">ADMIN LOGIN</button>
          </form>
        </div>
        <hr className="login-divider" />
        <div>
          <button className="google-button" onClick={handleGoogleLogin}>
            <GoogleLogo className="google-icon" />
            <span className="google-button-text">Sign in with Google</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;