import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { FaClock, FaCheck, FaTimesCircle, FaDownload, FaEdit, FaInfoCircle, FaArrowLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { getAttendances, getAttendancesByYearMonth, updateAttendance, checkAdmin } from '../services/api';
import swal from 'sweetalert';
import { useNavigate, Link } from 'react-router-dom';
import './StatusTag.css';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, eachMonthOfInterval, startOfMonth, endOfMonth } from 'date-fns';

const AdminAttendanceList = () => {
    const [attendances, setAttendances] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editingId, setEditingId] = useState(null);
    const navigate = useNavigate();
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [selectedLeaveTypes, setSelectedLeaveTypes] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');
    const [selectedYearMonth, setSelectedYearMonth] = useState(null);

    const STATUS_MAP = {
        'Pending': '待審核',
        'Approved': '已批准',
        'Rejected': '已拒絕'
    };

    useEffect(() => {
        const checkAdminStatus = async () => {
            try {
                const response = await checkAdmin();
                const isAdmin = response.data.is_admin;
                if (!isAdmin) {
                    navigate('/'); // 如果不是管理員，重定向到首頁
                    return;
                }
                fetchData(); // 如果是管理員，則獲取數據
            } catch (error) {
                console.error('Error checking admin status:', error);
                navigate('/'); // 如果出錯，也重定向到首頁
            }
        };

        checkAdminStatus();
    }, [navigate]);

    const currentYearMonth = useMemo(() => {
        const now = new Date();
        return {
            value: format(now, 'yyyy-MM'),
            label: format(now, 'yyyy-MM')
        };
    }, []);

    const yearMonthOptions = useMemo(() => {
        const startDate = new Date(2024, 7, 1); // 2024-07-01
        const endDate = endOfMonth(new Date()); // Current month's end
        const months = eachMonthOfInterval({ start: startDate, end: endDate });
        const options = months.map(date => ({
            value: format(date, 'yyyy-MM'),
            label: format(date, 'yyyy-MM')
        })).reverse(); // Reverse to show most recent first

        // Add "All" option
        return [{ value: 'all', label: '全部時間' }, ...options];
    }, []);

    useEffect(() => {
        setSelectedYearMonth(currentYearMonth);
    }, [currentYearMonth]);

    const fetchData = useCallback(() => {
        if (selectedYearMonth && selectedYearMonth.value !== 'all') {
            const [year, month] = selectedYearMonth.value.split('-');
            getAttendancesByYearMonth(year, month).then(response => {
                setAttendances(response.data);

                const uniqueEmployees = [...new Set(response.data.map(att => att.employee_name))];
                const uniqueLeaveTypes = [...new Set(response.data.map(att => att.leave_type))];
                const uniqueStatuses = [...new Set(response.data.map(att => att.status))];

                setEmployeeOptions(uniqueEmployees.map(name => ({ value: name, label: name })));
                setLeaveTypeOptions(uniqueLeaveTypes.map(type => ({ value: type, label: type })));
                setStatusOptions(uniqueStatuses.map(status => ({ value: status, label: STATUS_MAP[status] || status })));
            });
        } else {
            getAttendances().then(response => {
                setAttendances(response.data);

                const uniqueEmployees = [...new Set(response.data.map(att => att.employee_name))];
                const uniqueLeaveTypes = [...new Set(response.data.map(att => att.leave_type))];
                const uniqueStatuses = [...new Set(response.data.map(att => att.status))];

                setEmployeeOptions(uniqueEmployees.map(name => ({ value: name, label: name })));
                setLeaveTypeOptions(uniqueLeaveTypes.map(type => ({ value: type, label: type })));
                setStatusOptions(uniqueStatuses.map(status => ({ value: status, label: STATUS_MAP[status] || status })));
            });
        }
    }, [selectedYearMonth]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleStatusChange = useCallback((attendanceId, newStatus) => {
        const attendance = attendances.find(a => a.id === attendanceId);
        const attendanceDate = new Date(attendance.start_date);
        const today = new Date();
        const isPastMonth =
            attendanceDate.getFullYear() < today.getFullYear() ||
            (attendanceDate.getFullYear() === today.getFullYear() &&
                attendanceDate.getMonth() < today.getMonth());

        if (isPastMonth) {
            swal("錯誤", "無法編輯過去月份的請假記錄", "error");
            return;
        }

        setIsSubmitting(true);
        const updatedAttendance = { ...attendance, status: newStatus };

        updateAttendance(attendanceId, updatedAttendance)
            .then(() => {
                fetchData();
                swal("成功", `請假申請狀態已更新為${STATUS_MAP[newStatus]}`, "success");
            })
            .catch(error => {
                console.error('Error updating attendance status:', error);
                swal("錯誤", "更新請假狀態失敗", "error");
            })
            .finally(() => {
                setIsSubmitting(false);
                setEditingId(null);
            });
    }, [fetchData, attendances]);

    const formatDateTime = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleString('zh-TW', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        }).replace(/\//g, '-');
    };

    const formatToTaiwanDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('zh-TW', { year: 'numeric', month: '2-digit', day: '2-digit' });
    };

    const isEditable = useCallback((startDate) => {
        const today = new Date();
        const start = new Date(startDate);
        const oneDayAfterStart = new Date(start.getTime() + 24 * 60 * 60 * 1000);
        return today < oneDayAfterStart;
    }, []);

    const columns = useMemo(() => [
        {
            Header: 'Index',
            accessor: (row, i) => i + 1,
        },
        {
            Header: '員工姓名',
            accessor: 'employee_name',
        },
        {
            Header: '請假類型',
            accessor: 'leave_type',
            Cell: ({ value, row }) => {
                const reason = row.original.reason;
                if (!reason || reason.trim() === '') {
                    return value;
                }
                return (
                    <div className="tooltip leave-type-container">
                        {value}
                        <FaInfoCircle className="info-icon" />
                        <span className="tooltiptext">
                            {reason}
                        </span>
                    </div>
                );
            },
        },
        {
            Header: '申請時間',
            accessor: 'created_at',
            Cell: ({ value }) => formatDateTime(value),

        },
        {
            Header: '開始時間',
            accessor: row => ({ date: row.start_date, period: row.start_period }),
            Cell: ({ value }) => {
                const formattedDate = formatToTaiwanDate(value.date);
                return `${formattedDate} ${value.period || ''}`;
            },
        },
        {
            Header: '結束時間',
            accessor: row => ({ date: row.end_date, period: row.end_period }),
            Cell: ({ value }) => {
                if (!value.date) return '-';
                const formattedDate = formatToTaiwanDate(value.date);
                return `${formattedDate} ${value.period || ''}`;
            },
        },
        {
            Header: '請假天數',
            accessor: 'leave_days',
        },
        {
            Header: '扣款金額',
            accessor: 'deduction_amount',
            Cell: ({ value, row }) => {
                const status = row.original.status;
                const className = status === 'Approved' ? 'deduction-approved' : 'deduction-pending';

                if (status !== 'Approved' || value === null || value === undefined) {
                    return <span className={className}>-</span>;
                }
                return <span className={className}>NT$ {value.toLocaleString()}</span>;
            },
        },
        {
            Header: '請假狀態',
            accessor: 'status',
            Cell: ({ value, row }) => {
                const today = new Date();
                const attendanceDate = new Date(row.original.start_date);
                const isPastMonth =
                    attendanceDate.getFullYear() < today.getFullYear() ||
                    (attendanceDate.getFullYear() === today.getFullYear() &&
                        attendanceDate.getMonth() < today.getMonth());

                return isPastMonth ? (
                    <span className={`status-tag status-${value.toLowerCase()}`}>
                        {value === 'Pending' && <><FaClock className="icon-space" /> 待審核</>}
                        {value === 'Approved' && <><FaCheck className="icon-space" /> 已批准</>}
                        {value === 'Rejected' && <><FaTimesCircle className="icon-space" /> 已拒絕</>}
                    </span>
                ) : (
                    editingId === row.original.id ? (
                        <select
                            value={value}
                            onChange={(e) => handleStatusChange(row.original.id, e.target.value)}
                            disabled={isSubmitting}
                            className={`status-select status-${value.toLowerCase()}`}
                        >
                            <option value="Pending" className="status-pending">待審核</option>
                            <option value="Approved" className="status-approved">已批准</option>
                            <option value="Rejected" className="status-rejected">已拒絕</option>
                        </select>
                    ) : (
                        <span className={`status-tag status-${value.toLowerCase()}`}>
                            {value === 'Pending' && <><FaClock className="icon-space" /> 待審核</>}
                            {value === 'Approved' && <><FaCheck className="icon-space" /> 已批准</>}
                            {value === 'Rejected' && <><FaTimesCircle className="icon-space" /> 已拒絕</>}
                        </span>
                    )
                );
            },
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => {
                const today = new Date();
                const attendanceDate = new Date(row.original.start_date);
                const isPastMonth =
                    attendanceDate.getFullYear() < today.getFullYear() ||
                    (attendanceDate.getFullYear() === today.getFullYear() &&
                        attendanceDate.getMonth() < today.getMonth());

                return (
                    <div className="action-buttons">
                        <a
                            href={row.original.document_url || '#'}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={row.original.document_url ? '' : 'disabled'}
                        >
                            <FaDownload className={`icon download-icon ${row.original.document_url ? '' : 'disabled'}`} title="下載附件" />
                        </a>
                        <FaEdit
                            className={`icon edit-icon ${isPastMonth ? 'disabled' : ''}`}
                            onClick={() => !isPastMonth && setEditingId(editingId === row.original.id ? null : row.original.id)}
                            title={isPastMonth ? "無法編輯過去月份的記錄" : (editingId === row.original.id ? "完成編輯" : "編輯")}
                        />
                    </div>
                );
            },
        },
    ], [handleStatusChange, isSubmitting, editingId]);



    const filteredAttendances = useMemo(() => {
        return attendances.filter(attendance => {
            const employeeMatch = selectedEmployees.length === 0 || selectedEmployees.some(e => e.value === attendance.employee_name);
            const leaveTypeMatch = selectedLeaveTypes.length === 0 || selectedLeaveTypes.some(lt => lt.value === attendance.leave_type);
            const statusMatch = selectedStatuses.length === 0 || selectedStatuses.some(s => s.value === attendance.status);
            const dateMatch = (!startDate || new Date(attendance.start_date) >= startDate) &&
                (!endDate || new Date(attendance.end_date) <= endDate);
            const globalFilterMatch = !globalFilter ||
                Object.values(attendance).some(value =>
                    String(value).toLowerCase().includes(globalFilter.toLowerCase())
                );
            return employeeMatch && leaveTypeMatch && statusMatch && dateMatch && globalFilterMatch;
        }).map((attendance, index) => ({ ...attendance, Index: index + 1 }));
    }, [attendances, selectedEmployees, selectedLeaveTypes, selectedStatuses, startDate, endDate, globalFilter]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        setGlobalFilter: setReactTableGlobalFilter,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
    } = useTable(
        {
            columns,
            data: filteredAttendances,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const { pageIndex } = state;

    useEffect(() => {
        setReactTableGlobalFilter(globalFilter);
    }, [globalFilter, setReactTableGlobalFilter]);

    const generateTitle = useMemo(() => {
        if (!selectedYearMonth || selectedYearMonth.value === 'all') {
            return '請假管理 (全部時間)';
        }
        const [year, month] = selectedYearMonth.value.split('-');
        return `請假管理 (${year}年${month}月)`;
    }, [selectedYearMonth]);

    return (
        <div className="container theme-green">
            <div className="header">
                <Link to="/dashboard" className="back-button" title="返回 Dashboard">
                    <FaArrowLeft />
                </Link>
                <h1>{generateTitle}</h1>
            </div>
            <div className="filters">
                <input
                    value={globalFilter || ''}
                    onChange={e => setGlobalFilter(e.target.value || undefined)}
                    placeholder="搜尋..."
                />
                <Select
                    options={yearMonthOptions}
                    value={selectedYearMonth}
                    onChange={(option) => setSelectedYearMonth(option)}
                    placeholder="選擇年月"
                />
                <Select
                    value={selectedEmployees}
                    onChange={setSelectedEmployees}
                    options={employeeOptions}
                    placeholder="選擇員工"
                    isMulti
                />
                <Select
                    value={selectedLeaveTypes}
                    onChange={setSelectedLeaveTypes}
                    options={leaveTypeOptions}
                    placeholder="選擇請假類型"
                    isMulti
                />
                <Select
                    value={selectedStatuses}
                    onChange={setSelectedStatuses}
                    options={statusOptions}
                    placeholder="選擇狀態"
                    isMulti
                />
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="開始日期"
                    isClearable={true}
                />
                <DatePicker
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText="結束日期"
                    isClearable={true}
                />
            </div>
            <table {...getTableProps()} className="table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? <i className="fas fa-sort-down"></i>
                                                : <i className="fas fa-sort-up"></i>
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="pagination">
                <button
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                    className="pagination-button"
                >
                    <FaAngleDoubleLeft />
                </button>
                <button
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                    className="pagination-button"
                >
                    <FaAngleLeft />
                </button>
                <span className="pagination-info">
                    {pageIndex + 1} / {pageOptions.length}
                </span>
                <button
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                    className="pagination-button"
                >
                    <FaAngleRight />
                </button>
                <button
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                    className="pagination-button"
                >
                    <FaAngleDoubleRight />
                </button>
            </div>
        </div>
    );
};

export default AdminAttendanceList;