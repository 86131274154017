// src/services/api.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

// 创建一个axios实例
const axiosInstance = axios.create({
  baseURL: API_URL,
});

// 添加请求拦截器
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const checkAdmin = () => {
  return axiosInstance.get(`/user/check-admin/`);
};

// API请求函数
export const getEmployees = () => {
  return axiosInstance.get(`/employees/`);
};

export const getEmployee = (id) => {
  return axiosInstance.get(`/employees/${id}/`);
};

export const createEmployee = (data) => {
  return axiosInstance.post(`/employees/`, data);
};

export const updateEmployee = (id, data) => {
  return axiosInstance.put(`/employees/${id}/`, data);
};

export const deleteEmployee = (id) => {
  return axiosInstance.delete(`/employees/${id}/`);
};

export const getAttendances = () => {
  return axiosInstance.get(`/attendances/`);
};

export const getAttendancesByYearMonth = (year, month) => {
  return axiosInstance.get(`/attendances/year/${year}/month/${month}/`);
};

export const getAttendance = (id) => {
  return axiosInstance.get(`/attendances/${id}/`);
};

export const createAttendance = (data) => {
  return axiosInstance.post(`/attendances/`, data);
};

export const updateAttendance = (id, data) => {
  return axiosInstance.put(`/attendances/${id}/`, data);
};

export const patchAttendance = (id, data) => {
  return axiosInstance.patch(`/attendances/${id}/`, data);
};

export const deleteAttendance = (id) => {
  return axiosInstance.delete(`/attendances/${id}/`);
};

export const getReimbursementsByYearMonth = (year, month) => {
  return axiosInstance.get(`/reimbursements/year/${year}/month/${month}/`);
}

export const getReimbursements = () => {
  return axiosInstance.get(`/reimbursements/`);
};

export const getReimbursement = (id) => {
  return axiosInstance.get(`/reimbursements/${id}/`);
};

export const createReimbursement = (data) => {
  return axiosInstance.post(`/reimbursements/`, data);
};

export const updateReimbursement = (id, data) => {
  return axiosInstance.put(`/reimbursements/${id}/`, data);
};

export const patchReimbursement = (id, data) => {
  return axiosInstance.patch(`/reimbursements/${id}/`, data);
};

export const deleteReimbursement = (id) => {
  return axiosInstance.delete(`/reimbursements/${id}/`);
};

export const getSalaries = () => {
  return axiosInstance.get(`/salaries/`);
};

export const getSalary = (id) => {
  return axiosInstance.get(`/salaries/${id}/`);
};

export const createSalary = (data) => {
  return axiosInstance.post(`/salaries/`, data);
};

export const updateSalary = (id, data) => {
  return axiosInstance.put(`/salaries/${id}/`, data);
};

export const deleteSalary = (id) => {
  return axiosInstance.delete(`/salaries/${id}/`);
};

export const getYearMonths = () => {
  return axiosInstance.get(`/salaries/year-months/`);
};

export const getSalariesByYearMonth = (year, month) => {
  return axiosInstance.get(`/salaries/year/${year}/month/${month}/`);
};

export const googleLogin = (code) => {
  return axiosInstance.post(`/auth/google-login/`, { code });
};

export const adminLogin = (username, password) => {
  return axiosInstance.post(`/auth/admin-login/`, { username, password });
};

export const getOvertimesByYearMonth = (year, month) => {
  return axiosInstance.get(`/overtimes/year/${year}/month/${month}/`);
};

export const getOvertimes = () => {
  return axiosInstance.get(`/overtimes/`);
};

export const getOvertime = (id) => {
  return axiosInstance.get(`/overtimes/${id}/`);
};

export const createOvertime = (data) => {
  return axiosInstance.post(`/overtimes/`, data);
};

export const updateOvertime = (id, data) => {
  return axiosInstance.put(`/overtimes/${id}/`, data);
};

export const deleteOvertime = (id) => {
  return axiosInstance.delete(`/overtimes/${id}/`);
};

export const getContracts = () => {
  return axiosInstance.get(`/contracts/`);
};

export const getContract = (id) => {
  return axiosInstance.get(`/contracts/${id}/`);
};

export const createContract = (data) => {
  return axiosInstance.post(`/contracts/`, data);
};

export const updateContract = (id, data) => {
  return axiosInstance.put(`/contracts/${id}/`, data);
};

export const deleteContract = (id) => {
  return axiosInstance.delete(`/contracts/${id}/`);
};
