import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaDownload, FaArrowLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { getContracts } from '../services/api';
import { Link } from 'react-router-dom';

const EmployeeContractList = () => {
  const [contracts, setContracts] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterInput, setFilterInput] = useState("");

  const fetchData = useCallback(() => {
    getContracts().then(response => {
      setContracts(response.data);
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = useMemo(() => [
    {
      Header: 'Index',
      accessor: (row, i) => i + 1,
    },
    {
      Header: '合約標題',
      accessor: 'title',
    },
    {
      Header: '上傳日期',
      accessor: 'created_at',
      Cell: ({ value }) => formatToTaiwanDate(value),
    },
    {
      Header: '開始日期',
      accessor: 'start_date',
      Cell: ({ value }) => formatToTaiwanDate(value),
    },
    {
      Header: '結束日期',
      accessor: 'end_date',
      Cell: ({ value }) => formatToTaiwanDate(value),
    },
    {
      Header: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="action-buttons">
          <a
            href={row.original.contract_url || '#'}
            target="_blank"
            rel="noopener noreferrer"
            className={row.original.contract_url ? '' : 'disabled'}
          >
            <FaDownload 
              className={`icon download-icon ${row.original.contract_url ? '' : 'disabled'}`} 
              title={row.original.contract_url ? "下載合約" : "無文件"}
            />
          </a>
        </div>
      ),
    }
  ], []);

  const filteredContracts = useMemo(() => {
    return contracts.filter(contract => {
      const dateMatch = (!startDate || new Date(contract.start_date) >= startDate) &&
        (!endDate || new Date(contract.end_date) <= endDate);
      const globalFilterMatch = !filterInput || 
        Object.values(contract).some(value => 
          String(value).toLowerCase().includes(filterInput.toLowerCase())
        );
      return dateMatch && globalFilterMatch;
    });
  }, [contracts, startDate, endDate, filterInput]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns,
      data: filteredContracts,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex } = state;

  const formatToTaiwanDate = (date) => {
    if (!date) return '';
    const options = {
      timeZone: 'Asia/Taipei',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formatter = new Intl.DateTimeFormat('zh-TW', options);
    return formatter.format(new Date(date));
  };

  return (
    <div className="container theme-yellow">
      <div className="header">
        <Link to="/dashboard" className="back-button" title="返回 Dashboard">
          <FaArrowLeft />
        </Link>
        <h1>合約紀錄</h1>
      </div>
      <div>
        <div className="filters">
          <input
            value={filterInput || ''}
            onChange={e => setFilterInput(e.target.value || undefined)}
            placeholder="搜尋..."
          />
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="開始日期"
            isClearable={true}
          />
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="結束日期"
            isClearable={true}
          />
        </div>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span style={{ marginLeft: '10px' }}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <i className="fas fa-sort-down"></i>
                          : <i className="fas fa-sort-up"></i>
                        : null}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button 
            onClick={() => gotoPage(0)} 
            disabled={!canPreviousPage}
            className="pagination-button"
          >
            <FaAngleDoubleLeft />
          </button>
          <button 
            onClick={() => previousPage()} 
            disabled={!canPreviousPage}
            className="pagination-button"
          >
            <FaAngleLeft />
          </button>
          <span className="pagination-info">
            {pageIndex + 1} / {pageOptions.length}
          </span>
          <button 
            onClick={() => nextPage()} 
            disabled={!canNextPage}
            className="pagination-button"
          >
            <FaAngleRight />
          </button>
          <button 
            onClick={() => gotoPage(pageCount - 1)} 
            disabled={!canNextPage}
            className="pagination-button"
          >
            <FaAngleDoubleRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmployeeContractList;