// AttendanceList.js

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaClock, FaCheck, FaTimesCircle, FaEdit, FaTrashAlt, FaDownload, FaTimes, FaSave, FaPlus, FaArrowLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaInfoCircle } from 'react-icons/fa';
import { getAttendances, getAttendancesByYearMonth, createAttendance, updateAttendance, deleteAttendance } from '../services/api';
import swal from 'sweetalert';
import './Layout.css';
import { Link } from 'react-router-dom';
import { format, eachMonthOfInterval, startOfMonth, endOfMonth } from 'date-fns';

const AttendanceList = () => {
  const [attendances, setAttendances] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentAttendance, setCurrentAttendance] = useState(null);
  const [formValues, setFormValues] = useState({
    leave_type: '',
    start_date: '',
    start_period: '',
    end_date: '',
    end_period: '',
    reason: '',
    document: null,
    status: 'Pending',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isEndDateFilled, setIsEndDateFilled] = useState(false);

  const LEAVE_TYPES  = [
    { value: '病假', label: '病假' },
    { value: '事假', label: '事假' },
    { value: '特休', label: '特休' },
    { value: '生理假', label: '生理假' },
    { value: '公假', label: '公假' },
    { value: '喪假', label: '喪假' },
    { value: '婚假', label: '婚假' },
    { value: '產假', label: '產假' },
    { value: '陪產假', label: '陪產假' },
    { value: '公傷病假', label: '公傷病假' },
    { value: '其他', label: '其他' },
  ];

  const LEAVE_PERIODS = [
    { value: '上午', label: '上午' },
    { value: '下午', label: '下午' },
    { value: '全天', label: '全天' },
  ];

  const STATUS_MAP = {
    'Pending': '待審核',
    'Approved': '已批准',
    'Rejected': '已拒絕'
  };

  const STATUS_OPTIONS = [
    { value: 'Pending', label: '待審核' },
    { value: 'Approved', label: '已批准' },
    { value: 'Rejected', label: '已拒絕' }
  ];

  const currentYearMonth = useMemo(() => {
    const now = new Date();
    return {
      value: format(now, 'yyyy-MM'),
      label: format(now, 'yyyy-MM')
    };
  }, []);

  const [selectedYearMonth, setSelectedYearMonth] = useState(currentYearMonth);

  const yearMonthOptions = useMemo(() => {
    const startDate = new Date(2024, 7, 1); // 2024-07-01
    const endDate = endOfMonth(new Date()); // Current month's end
    const months = eachMonthOfInterval({ start: startDate, end: endDate });
    const options = months.map(date => ({
      value: format(date, 'yyyy-MM'),
      label: format(date, 'yyyy-MM')
    })).reverse(); // Reverse to show most recent first
    
    // Add "All" option
    return [{ value: 'all', label: '全部時間' }, ...options];
  }, []);

  const fetchData = useCallback(() => {
    if (selectedYearMonth && selectedYearMonth.value !== 'all') {
      const [year, month] = selectedYearMonth.value.split('-');
      getAttendancesByYearMonth(year, month).then(response => {
        setAttendances(response.data);
      });
    } else {
      getAttendances().then(response => {
        setAttendances(response.data);
      });
    }
  }, [selectedYearMonth]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  useEffect(() => {
    if (currentAttendance) {
      setFormValues({
        leave_type: currentAttendance.leave_type || '',
        start_date: formatDateForInput(currentAttendance.start_date),
        start_period: currentAttendance.start_period || '',
        end_date: currentAttendance.end_date ? formatDateForInput(currentAttendance.end_date) : '',
        end_period: currentAttendance.end_period || '',
        reason: currentAttendance.reason || '',
        document: currentAttendance.document_url || null,
        status: currentAttendance.status || 'Pending',
      });
    } else {
      setFormValues({
        leave_type: '',
        start_date: '',
        start_period: '',
        end_date: '',
        end_period: '',
        reason: '',
        document: null,
        status: 'Pending',
      });
    }
  }, [currentAttendance]);

  const handleAdd = () => {
    setCurrentAttendance(null);
    setIsEditing(false);
    setFormValues({
      leave_type: '',
      start_date: '',
      start_period: '',
      end_date: '',
      end_period: '',
      reason: '',
      document: null,
      status: 'Pending',
    });
    setIsFormVisible(true);
  };

  const handleEdit = useCallback((attendance) => {
    setCurrentAttendance(attendance);
    setIsEditing(true);
    setFormValues({
      leave_type: attendance.leave_type || '',
      start_date: formatDateForInput(attendance.start_date),
      start_period: attendance.start_period || '',
      end_date: attendance.end_date ? formatDateForInput(attendance.end_date) : '',
      end_period: attendance.end_period || '',
      reason: attendance.reason || '',
      document: attendance.document_url || null,
      status: attendance.status || 'Pending',
    });
    setIsFormVisible(true);
  }, []);

  const handleDelete = useCallback((attendance) => {
    const currentDate = new Date();
    const attendanceDate = new Date(attendance.start_date);
    const isPastMonth = 
      attendanceDate.getFullYear() < currentDate.getFullYear() ||
      (attendanceDate.getFullYear() === currentDate.getFullYear() && 
       attendanceDate.getMonth() < currentDate.getMonth());

    if (isPastMonth) {
      swal("錯誤", "無法刪除過去月份的請假記錄", "error");
      return;
    }

    swal({
      title: "確認刪除",
      text: "您確定要刪除這筆請假記錄嗎？",
      icon: "warning",
      buttons: {
        cancel: {
          text: "取消",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "確定",
          value: true,
          visible: true,
          className: "",
          closeModal: true
        }
      },
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteAttendance(attendance.id).then(() => {
            fetchData();
            swal("成功", "請假記錄已成功刪除！", "success");
          });
        }
      });
  }, [fetchData]);

  const columns = useMemo(() => [
    {
      Header: 'Index',
      accessor: (row, i) => i + 1,
    },
    {
      Header: '請假類型',
      accessor: 'leave_type',
      Cell: ({ value, row }) => {
        const reason = row.original.reason;
        if (!reason || reason.trim() === '') {
          return value;
        }
        return (
          <div className="tooltip leave-type-container">
            {value}
            <FaInfoCircle className="info-icon" />
            <span className="tooltiptext">
              {reason}
            </span>
          </div>
        );
      },
    },
    {
      Header: '申請時間',
      accessor: 'created_at',
      Cell: ({ value }) => formatDateTime(value),

    },
    {
      Header: '開始時間',
      accessor: row => ({ date: row.start_date, period: row.start_period }),
      Cell: ({ value }) => {
        const formattedDate = formatToTaiwanDate(value.date);
        return `${formattedDate} ${value.period || ''}`;
      },
    },
    {
      Header: '結束時間',
      accessor: row => ({ date: row.end_date, period: row.end_period }),
      Cell: ({ value }) => {
        if (!value.date) return '-';
        const formattedDate = formatToTaiwanDate(value.date);
        return `${formattedDate} ${value.period || ''}`;
      },
    },
    {
      Header: '請假天數',
      accessor: 'leave_days',
    },
    {
      Header: '請假狀態',
      accessor: 'status',
      Cell: ({ value }) => (
        <span className={`status-tag status-${value.toLowerCase()}`}>
          {value === 'Pending' && <><FaClock className="icon-space" /> 待審核</>}
          {value === 'Approved' && <><FaCheck className="icon-space" /> 已批准</>}
          {value === 'Rejected' && <><FaTimesCircle className="icon-space" /> 已拒絕</>}
        </span>
      ),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => {
        const attendance = row.original;
        const currentDate = new Date();
        const attendanceDate = new Date(attendance.start_date);
        const isPastMonth = 
          attendanceDate.getFullYear() < currentDate.getFullYear() ||
          (attendanceDate.getFullYear() === currentDate.getFullYear() && 
           attendanceDate.getMonth() < currentDate.getMonth());
        const isEditable = attendance.status === 'Pending';

        return (
          <div className="action-buttons">
            {attendance.document_url ? (
              <a href={attendance.document_url} target="_blank" rel="noopener noreferrer">
                <FaDownload className="icon download-icon available" />
              </a>
            ) : (
              <FaDownload className="icon download-icon disabled" />
            )}
            <FaEdit
              onClick={() => isEditable && !isPastMonth && handleEdit(attendance)}
              className={`icon edit-icon ${(!isEditable || isPastMonth) ? 'disabled' : ''}`}
            />
            <FaTrashAlt
              onClick={() => isEditable && !isPastMonth && handleDelete(attendance)}
              className={`icon delete-icon ${(!isEditable || isPastMonth) ? 'disabled' : ''}`}
            />
          </div>
        );
      },
    }
  ], [handleDelete, handleEdit]);

  const filteredAttendances = useMemo(() => {
    return attendances.filter(attendance => {
      const leaveTypeMatch = !selectedLeaveType || selectedLeaveType.length === 0 || 
        selectedLeaveType.some(option => option.value === attendance.leave_type);
      const statusMatch = !selectedStatus || selectedStatus.length === 0 || 
        selectedStatus.some(option => option.value === attendance.status);
      const dateMatch = (!startDate || new Date(attendance.start_date) >= startDate) &&
        (!endDate || new Date(attendance.end_date) <= endDate);
      return leaveTypeMatch && statusMatch && dateMatch;
    });
  }, [attendances, selectedLeaveType, selectedStatus, startDate, endDate]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns,
      data: filteredAttendances,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex } = state;

  const formatDateTime = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleString('zh-TW', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).replace(/\//g, '-');
  };

  const formatToTaiwanDate = (dateString) => {
    if (!dateString) return '';
    const options = {
      timeZone: 'Asia/Taipei',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formatter = new Intl.DateTimeFormat('zh-TW', options);
    return formatter.format(new Date(dateString)).replace(/\//g, '-');
  };

  const getCurrentMonthRange = () => {
    const now = new Date();
    const firstDay = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
    const lastDay = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 0));
    return {
      min: firstDay.toISOString().split('T')[0],
      max: lastDay.toISOString().split('T')[0]
    };
  };

  const getCurrentDate = () => {
    const now = new Date();
    const taiwanTime = new Date(now.toLocaleString('en-US', { timeZone: 'Asia/Taipei' }));
    return taiwanTime.toISOString().split('T')[0];
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
  
    // Check if end date requires end period
    if (formValues.end_date && !formValues.end_period) {
      swal("錯誤", "請選擇結束時段", "error");
      return;
    }
  
    // Check if leave spans multiple months
    const startDate = new Date(formValues.start_date);
    const endDate = new Date(formValues.end_date || formValues.start_date);
  
    if (startDate.getMonth() !== endDate.getMonth() || startDate.getFullYear() !== endDate.getFullYear()) {
      swal("錯誤", "請假不可跨月，請分開申請", "error");
      return;
    }
  
    // Check same-day leave periods
    if (startDate.getTime() === endDate.getTime() && 
        formValues.start_period === '下午' && 
        (formValues.end_period === '上午' || formValues.end_period === '全天')) {
      swal("錯誤", "同一天的請假，結束時段不能早於開始時段", "error");
      return;
    }
  
    setIsSubmitting(true);

    const form = event.target;
    const data = new FormData();

    data.append('leave_type', formValues.leave_type);
    data.append('start_date', formValues.start_date);
    data.append('start_period', formValues.start_period);
    data.append('end_date', formValues.end_date || '');
    data.append('end_period', formValues.end_period || '');
    data.append('reason', formValues.reason || '');
    if (form.document?.files[0]) {
      data.append('document', form.document.files[0]);
    }
    data.append('status', 'Pending');
    data.append('auto_calculate_deduction', 'true');

    const apiCall = isEditing
      ? updateAttendance(currentAttendance.id, data)
      : createAttendance(data);

    apiCall
      .then(() => {
        fetchData();
        setIsFormVisible(false);
        if (isEditing) {
          setIsEditing(false);
          setCurrentAttendance(null);
        }
        swal("成功", isEditing ? "請假記錄已更新" : "請假申請已提交", "success");
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
        if (error.response && error.response.status === 404) {
          swal("申請失敗", "請假日期必須在當前月份內", "error");
        } else {
          swal("錯誤", "提交失敗，請稍後再試", "error");
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleClose = () => {
    setIsFormVisible(false);
  };

  useEffect(() => {
    const selectElement = document.querySelector('.status-select');
    if (selectElement) {
      selectElement.className = `status-select status-${formValues.status.toLowerCase()}`;
    }
  }, [formValues.status]);

  useEffect(() => {
    const uniqueLeaveTypes = [...new Set(attendances.map(att => att.leave_type))];
    setLeaveTypeOptions(uniqueLeaveTypes.map(type => ({ value: type, label: type })));
    setStatusOptions(STATUS_OPTIONS);
  }, [attendances]);

  const handleEndPeriodChange = (option) => {
    const startDate = new Date(formValues.start_date);
    const endDate = new Date(formValues.end_date);
    
    if (startDate.getTime() === endDate.getTime() && 
        formValues.start_period === '下午' && 
        (option.value === '上午' || option.value === '全天')) {
      swal("錯誤", "同一天的請假，結束時段不能早於開始時段", "error");
      return;
    }
    
    setFormValues({ ...formValues, end_period: option.value });
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    
    if (newEndDate) {
      const startDate = new Date(formValues.start_date);
      const endDate = new Date(newEndDate);
  
      if (startDate.getMonth() !== endDate.getMonth() || startDate.getFullYear() !== endDate.getFullYear()) {
        swal("錯誤", "請假不可跨月，請分開申請", "error");
        return;
      }
    }
  
    setFormValues(prev => ({
      ...prev,
      end_date: newEndDate,
      end_period: newEndDate ? prev.end_period : ''  // Keep end period if end date is present; otherwise clear
    }));
    
    setIsEndDateFilled(!!newEndDate);
  };

  const generateTitle = useMemo(() => {
    if (!selectedYearMonth || selectedYearMonth.value === 'all') {
      return '請假紀錄 (全部時間)';
    }
    const [year, month] = selectedYearMonth.value.split('-');
    return `請假紀錄 (${year}年${month}月)`;
  }, [selectedYearMonth]);

  return (
    <div className="container theme-green">
      <div className="header">
        <Link to="/dashboard" className="back-button" title="返回 Dashboard">
          <FaArrowLeft />
        </Link>
        <h1>{generateTitle}</h1>
      </div>
      {isFormVisible && <div className="overlay visible" onClick={handleClose}></div>}
      <div className={`modal ${isFormVisible ? 'visible' : ''}`}>
        <div className="form-container">
          <h2>{isEditing ? '編輯請假' : '申請請假'}</h2>
          <form onSubmit={handleFormSubmit} className="attendance-form" encType="multipart/form-data">
            <div className="form-group">
            <label>
                請假類型: <span className="required">*</span>
                <Select
                  options={LEAVE_TYPES}
                  value={LEAVE_TYPES.find(option => option.value === formValues.leave_type) || null}
                  onChange={option => setFormValues({ ...formValues, leave_type: option ? option.value : '' })}
                  placeholder="請選擇請假類型"
                  required
                />
              </label>
              <label>
                請假證明:
                <input type="file" name="document" />
              </label>
              <label>
                開始日期: <span className="required">*</span>
                <input
                  type="date"
                  name="start_date"
                  value={formValues.start_date}
                  onChange={(e) => setFormValues({ ...formValues, start_date: e.target.value })}
                  min={getCurrentMonthRange().min}
                  max={getCurrentMonthRange().max}
                  required
                />
              </label>
              <label>
                開始時段: <span className="required">*</span>
                <Select
                  options={LEAVE_PERIODS}
                  value={LEAVE_PERIODS.find(option => option.value === formValues.start_period) || null}
                  onChange={option => setFormValues({ ...formValues, start_period: option ? option.value : '' })}
                  placeholder="請選擇開始時段"
                  required
                />
              </label>
              <label>
                結束日期:
                <input
                  type="date"
                  name="end_date"
                  value={formValues.end_date}
                  onChange={handleEndDateChange}
                  min={formValues.start_date || getCurrentMonthRange().min}
                  max={getCurrentMonthRange().max}
                />
              </label>
              <label>
                結束時段:
                <Select
                  options={LEAVE_PERIODS}
                  value={LEAVE_PERIODS.find(option => option.value === formValues.end_period) || null}
                  onChange={handleEndPeriodChange}
                  isDisabled={!formValues.end_date}
                  placeholder="請選擇結束時段"
                  required={isEndDateFilled}
                />
              </label>
              <label>
                請假原因:
                <textarea name="reason" value={formValues.reason} onChange={(e) => setFormValues({ ...formValues, reason: e.target.value })} />
              </label>
              
            </div>
            <div className="form-actions">
              <button type="button" className="icon-button cancel-button" onClick={handleClose} disabled={isSubmitting}>
                <FaTimes className="icon" /> 取消
              </button>
              <button type="submit" className="icon-button save-button" disabled={isSubmitting}>
                <FaSave className="icon" /> {isSubmitting ? '儲存中...' : '儲存'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <div className="filters">
          <input
            value={globalFilter || ''}
            onChange={e => setGlobalFilter(e.target.value || undefined)}
            placeholder="搜尋..."
          />
          <Select
            options={yearMonthOptions}
            value={selectedYearMonth}
            onChange={(option) => setSelectedYearMonth(option)}
            placeholder="選擇年月"
          />
          <Select
            options={leaveTypeOptions}
            value={selectedLeaveType}
            onChange={options => setSelectedLeaveType(options)}
            placeholder="選擇請假類型"
            isMulti
          />
          <Select
            options={statusOptions}
            value={selectedStatus}
            onChange={options => setSelectedStatus(options)}
            placeholder="選擇狀態"
            isMulti
          />
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="開始日期"
            isClearable={true}
          />
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="結束日期"
            isClearable={true}
          />
          <button className="add-button" onClick={handleAdd}>
            <FaPlus className="icon" /> 申請請假
          </button>
        </div>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span style={{ marginLeft: '10px' }}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <i className="fas fa-sort-down"></i>
                          : <i className="fas fa-sort-up"></i>
                        : null}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button 
            onClick={() => gotoPage(0)} 
            disabled={!canPreviousPage}
            className="pagination-button"
          >
            <FaAngleDoubleLeft />
          </button>
          <button 
            onClick={() => previousPage()} 
            disabled={!canPreviousPage}
            className="pagination-button"
          >
            <FaAngleLeft />
          </button>
          <span className="pagination-info">
          {pageIndex + 1} / {pageOptions.length}
          </span>
          <button 
            onClick={() => nextPage()} 
            disabled={!canNextPage}
            className="pagination-button"
          >
            <FaAngleRight />
          </button>
          <button 
            onClick={() => gotoPage(pageCount - 1)} 
            disabled={!canNextPage}
            className="pagination-button"
          >
            <FaAngleDoubleRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AttendanceList;