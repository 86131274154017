// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EmployeeList from './components/EmployeeList';
import AttendanceList from './components/EmployeeAttendanceList';
import AdminAttendanceList from './components/AdminAttendanceList';
import EmployeeReimbursementList from './components/EmployeeReimbursementList';
import AdminReimbursementList from './components/AdminReimbursementList';
import AdminContractList from './components/AdminContractList'
import EmployeeContractList from './components/EmployeeContractList';
import OverTimeList from './components/EmployeeOverTimeList';
import AdminOverTimeList from './components/AdminOverTimeList';
import SalaryList from './components/SalaryList';
import AdminDashboard from './components/DashBoard'
import Login from './components/Login'; // 新增這行
import './App.css';
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/admin/employees" element={<EmployeeList />} />
          <Route path="employee/attendances" element={<AttendanceList />} />
          <Route path="/admin/attendances" element={<AdminAttendanceList />} />
          <Route path="employee/reimbursements" element={<EmployeeReimbursementList />} />
          <Route path="/admin/reimbursements" element={<AdminReimbursementList />} />
          <Route path="employee/overtimes" element={<OverTimeList />} />
          <Route path="/admin/overtimes" element={<AdminOverTimeList />} />
          <Route path='/admin/contracts' element={<AdminContractList />} />
          <Route path='employee/contracts' element={<EmployeeContractList/>} />
          <Route path="/admin/salaries" element={<SalaryList />} />
          <Route path="/dashboard" element={<AdminDashboard />} />
          <Route path="/" element={<Login />} />
          {/* <Route path="/" element={<div>Home</div>} /> */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;