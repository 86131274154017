// SalaryList.js
import React, { useEffect, useState, useCallback } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import { getSalariesByYearMonth, getYearMonths, updateSalary, checkAdmin } from '../services/api';
import { useNavigate, Link } from 'react-router-dom';
import { FaInfoCircle, FaEdit, FaDownload, FaSave, FaTimes, FaEye, FaArrowLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa'; // Import FaEye for Preview icon
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import './SalaryList.css';
import { generateSalaryHTML } from './salaryTemplate';

// Helper function to format numbers with thousand separators and prepend "NT$"
const formatCurrency = (number) => {
  return `NT$ ${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

const SalariesByYearMonth = () => {
  const navigate = useNavigate();
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [salaries, setSalaries] = useState([]);
  const [yearMonths, setYearMonths] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isHtmlVisible, setIsHtmlVisible] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [currentSalary, setCurrentSalary] = useState(null);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [formValues, setFormValues] = useState({
    base_salary: 0,
    performance_bonus: 0,
    year_end_bonus: 0,
    total_reimbursement: 0,
    personal_leave_deduction: 0,
    sick_leave_deduction: 0,
    personal_income_tax_submission: 0,
  });

  const fetchData = useCallback(() => {
    getYearMonths()
      .then(response => {
        setYearMonths(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the year-months data!', error);
      });

    getSalariesByYearMonth(year, month)
      .then(response => {
        setSalaries(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the salaries data!', error);
      });
  }, [year, month]);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const response = await checkAdmin();
        const isAdmin = response.data.is_admin;
        if (!isAdmin) {
          navigate('/'); // 如果不是管理員，重定向到首頁
          return;
        }
        fetchData(); // 如果是管理員，則獲取數據
      } catch (error) {
        console.error('Error checking admin status:', error);
        navigate('/'); // 如果出錯，也重定向到首頁
      }
    };

    checkAdminStatus();
  }, [navigate, fetchData]);

  useEffect(() => {
    if (currentSalary) {
      setFormValues({
        base_salary: currentSalary.base_salary || 0,
        performance_bonus: currentSalary.performance_bonus || 0,
        year_end_bonus: currentSalary.year_end_bonus || 0,
        total_reimbursement: currentSalary.total_reimbursement || 0,
        personal_leave_deduction: currentSalary.personal_leave_deduction || 0,
        sick_leave_deduction: currentSalary.sick_leave_deduction || 0,
        personal_income_tax_submission: currentSalary.personal_income_tax_submission || 0,
      });
    }
  }, [currentSalary]);

  const handleSubmit = (event) => {
    event.preventDefault();
    getSalariesByYearMonth(year, month)
      .then(response => {
        setSalaries(response.data);
        navigate(`/salaries`);
      })
      .catch(error => {
        console.error('There was an error fetching the salaries data!', error);
      });
  };

  const handleEdit = (salary) => {
    setCurrentSalary(salary);
    setIsFormVisible(true);
  };

  // Function to calculate period and payment_date
  const calculatePeriodAndPaymentDate = (year, month) => {
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const lastDayOfMonth = new Date(year, month, 0);
    const paymentDate = new Date(year, month, 10);

    const formatDate = (date) => {
      return `${date.getFullYear()}年${String(date.getMonth() + 1).padStart(2, '0')}月`;
    };

    return {
      period: `${formatDate(firstDayOfMonth)}`,
      payment_date: `${paymentDate.getFullYear()}年${String(paymentDate.getMonth() + 1).padStart(2, '0')}月${String(paymentDate.getDate()).padStart(2, '0')}日`
    };
  };

  // When showing HTML for a salary, calculate the period and payment_date and pass them to the template
  const handleShowHtml = useCallback((salary) => {
    const { period, payment_date } = calculatePeriodAndPaymentDate(year, month);
    const salaryWithDates = {
      ...salary,
      period,
      payment_date
    };
    const salaryHTML = generateSalaryHTML(salaryWithDates);
    setHtmlContent(salaryHTML);
    setIsPreviewModalVisible(true);
  }, [year, month]);

  const handlePreviewClose = () => {
    setIsPreviewModalVisible(false);
    setHtmlContent('');
  };

  const handleDownloadPdf = (salary) => {
    const { period, payment_date } = calculatePeriodAndPaymentDate(year, month);
    const salaryWithDates = {
      ...salary,
      period,
      payment_date
    };
    const salaryHTML = generateSalaryHTML(salaryWithDates);

    // Create a hidden element to hold the HTML content
    const element = document.createElement('div');
    element.innerHTML = salaryHTML;
    element.style.position = 'absolute';
    element.style.top = '-9999px';
    element.style.width = '210mm'; // A4 paper size
    element.style.padding = '10mm'; // Padding to ensure margins
    element.style.fontSize = '14px'; // Ensure the font size is consistent with preview
    document.body.appendChild(element);

    // Use html2canvas to capture the element as an image
    html2canvas(element, { scale: 2 }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdfDoc = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdfDoc.getImageProperties(imgData);
      const pdfWidth = pdfDoc.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdfDoc.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdfDoc.save(`${salary.employee_name}_salary.pdf`);
      document.body.removeChild(element); // Clean up the hidden element
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    const updatedSalary = {
      base_salary: formValues.base_salary || 0,
      performance_bonus: formValues.performance_bonus || 0,
      year_end_bonus: formValues.year_end_bonus || 0,
      personal_leave_deduction: formValues.personal_leave_deduction || 0,
      sick_leave_deduction: formValues.sick_leave_deduction || 0,
      personal_income_tax_submission: formValues.personal_income_tax_submission || 0,
    };

    updateSalary(currentSalary.id, updatedSalary).then(() => {
      getSalariesByYearMonth(year, month).then(response => {
        setSalaries(response.data);
        setIsFormVisible(false);
        setCurrentSalary(null);
      });
    });
  };

  const handleClose = () => {
    setIsFormVisible(false);
    setCurrentSalary(null);
  };

  const handleHtmlClose = () => {
    setIsHtmlVisible(false);
    setHtmlContent('');
  };

  const columns = React.useMemo(() => [
    {
      Header: 'Index',
      accessor: (row, i) => i + 1,
    },
    {
      Header: '名稱',
      accessor: 'employee_name',
    },
    {
      Header: '基本工資',
      accessor: 'base_salary',
      Cell: ({ value }) => formatCurrency(value),
    },
    {
      Header: '其他加項',
      accessor: salary => salary.total_receivables - salary.base_salary,
      Cell: ({ row }) => {
        const otherAdditions = row.original.total_receivables - row.original.base_salary;
        const additionItems = [
          { label: '加班工資', value: row.original.overtime_pay },
          { label: '績效獎金', value: row.original.performance_bonus },
          { label: '年終獎金', value: row.original.year_end_bonus },
          { label: '報銷總額', value: row.original.total_reimbursement }
        ].filter(item => item.value > 0);

        return otherAdditions === 0 ? (
          formatCurrency(otherAdditions)
        ) : (
          <div className="tooltip">
            {formatCurrency(otherAdditions)}
            <FaInfoCircle className="info-icon" />
            <span className="tooltiptext">
              {additionItems.map((item, index) => (
                <React.Fragment key={index}>
                  + {item.label}: {formatCurrency(item.value)}
                  {index < additionItems.length - 1 && <br />}
                </React.Fragment>
              ))}
            </span>
          </div>
        );
      },
    },
    {
      Header: '應扣金額',
      accessor: 'total_deductions',
      Cell: ({ row }) => {
        const deductionItems = [
          { label: '員工勞保', value: row.original.employee_labor_insurance },
          { label: '員工健保', value: row.original.employee_health_insurance },
          { label: '事假扣款', value: row.original.personal_leave_deduction },
          { label: '病假扣款', value: row.original.sick_leave_deduction },
          { label: '所得稅提交', value: row.original.personal_income_tax_submission }
        ].filter(item => item.value > 0);

        return row.original.total_deductions === 0 ? (
          formatCurrency(row.original.total_deductions)
        ) : (
          <div className="tooltip">
            {formatCurrency(row.original.total_deductions)}
            <FaInfoCircle className="info-icon" />
            <span className="tooltiptext">
              {deductionItems.map((item, index) => (
                <React.Fragment key={index}>
                  - {item.label}: {formatCurrency(item.value)}
                  {index < deductionItems.length - 1 && <br />}
                </React.Fragment>
              ))}
            </span>
          </div>
        );
      },
    },
    {
      Header: '員工實領',
      accessor: 'total_salary',
      Cell: ({ row }) => {
        const salaryItems = [
          { label: '基本工資', value: row.original.base_salary },
          { label: '其他加項', value: row.original.total_receivables - row.original.base_salary },
          { label: '應扣金額', value: -row.original.total_deductions }
        ].filter(item => item.value !== 0);

        return (
          <div className="tooltip">
            <span className="salary-tag employee-salary-tag">{formatCurrency(row.original.total_salary)}</span>
            {salaryItems.length > 1 && (
              <>
                <FaInfoCircle className="info-icon" />
                <span className="tooltiptext">
                  {salaryItems.map((item, index) => (
                    <React.Fragment key={index}>
                      {item.value >= 0 ? '+' : '-'} {item.label}: {formatCurrency(Math.abs(item.value))}
                      {index < salaryItems.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </span>
              </>
            )}
          </div>
        );
      },
    },
    {
      Header: '公司實付',
      accessor: salary => salary.total_company_payment,
      Cell: ({ row }) => {
        const companyPaymentItems = [
          { label: '員工實領', value: row.original.total_salary },
          { label: '員工勞保', value: row.original.employee_labor_insurance },
          { label: '員工健保', value: row.original.employee_health_insurance },
          { label: '公司勞保', value: row.original.company_labor_insurance },
          { label: '公司健保', value: row.original.company_health_insurance },
          { label: '勞工退休', value: row.original.labor_retirement }
        ].filter(item => item.value > 0);

        return (
          <div className="tooltip">
            <span className="salary-tag company-payment-tag">{formatCurrency(row.original.total_company_payment)}</span>
            {companyPaymentItems.length > 1 && (
              <>
                <FaInfoCircle className="info-icon" />
                <span className="tooltiptext">
                  {companyPaymentItems.map((item, index) => (
                    <React.Fragment key={index}>
                      + {item.label}: {formatCurrency(item.value)}
                      {index < companyPaymentItems.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </span>
              </>
            )}
          </div>
        );
      },
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="action-buttons">
          <FaEdit onClick={() => handleEdit(row.original)} className="icon edit-icon" />
          <FaEye onClick={() => handleShowHtml(row.original)} className="icon preview-icon" /> {/* Preview Icon */}
          <FaDownload onClick={() => handleDownloadPdf(row.original)} className="icon download-icon" /> {/* Download as PDF */}
        </div>
      ),
    },
  ], [handleShowHtml]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns,
      data: salaries,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex } = state;

  return (
    <div className="container theme-mint">
      <div className="header">
        <Link to="/dashboard" className="back-button" title="返回 Dashboard">
          <FaArrowLeft />
        </Link>
        <h1>薪資管理 ({year}年{month}月)</h1>
      </div>
      {isFormVisible && <div className="overlay visible" onClick={handleClose}></div>}
      {isHtmlVisible && (
        <div className="overlay visible" onClick={handleHtmlClose}>
          <div className="modal visible">
            <div className="html-content" dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
          </div>
        </div>
      )}
      {isPreviewModalVisible && (
        <div className="overlay visible" onClick={handlePreviewClose}>
          <div className="modal preview-modal visible" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <div className="html-content" dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
            </div>
          </div>
        </div>
      )}
      <div className={`modal ${isFormVisible ? 'visible' : ''}`}>
        <h2>編輯薪水</h2>
        <form onSubmit={handleFormSubmit} className="employee-form">
          <div className="form-group">
            <label>
              月薪:
              <input
                type="number"
                name="base_salary"
                value={formValues.base_salary}
                onChange={(e) => setFormValues({ ...formValues, base_salary: e.target.value })}
              />
            </label>
            <label>
              績效獎金:
              <input
                type="number"
                name="performance_bonus"
                value={formValues.performance_bonus}
                onChange={(e) => setFormValues({ ...formValues, performance_bonus: e.target.value })}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              年終獎金:
              <input
                type="number"
                name="year_end_bonus"
                value={formValues.year_end_bonus}
                onChange={(e) => setFormValues({ ...formValues, year_end_bonus: e.target.value })}
              />
            </label>
            <label>
              報銷總額:
              <input
                type="number"
                name="total_reimbursement"
                value={formValues.total_reimbursement}
                onChange={(e) => setFormValues({ ...formValues, total_reimbursement: e.target.value })}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              事假扣款:
              <input
                type="number"
                name="personal_leave_deduction"
                value={formValues.personal_leave_deduction}
                onChange={(e) => setFormValues({ ...formValues, personal_leave_deduction: e.target.value })}
              />
            </label>
            <label>
              病假扣款:
              <input
                type="number"
                name="sick_leave_deduction"
                value={formValues.sick_leave_deduction}
                onChange={(e) => setFormValues({ ...formValues, sick_leave_deduction: e.target.value })}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              個人所得稅提交:
              <input
                type="number"
                name="personal_income_tax_submission"
                value={formValues.personal_income_tax_submission}
                onChange={(e) => setFormValues({ ...formValues, personal_income_tax_submission: e.target.value })}
              />
            </label>
            <label style={{ visibility: 'hidden' }}>
              {/* This is an empty label to maintain the layout */}
              <input type="text" style={{ visibility: 'hidden' }} />
            </label>
          </div>
          <div className="form-actions">
            <button type="button" className="icon-button cancel-button" onClick={handleClose}>
              <FaTimes className="icon" /> 取消
            </button>
            <button type="submit" className="icon-button save-button">
              <FaSave className="icon" /> 儲存
            </button>
          </div>
        </form>
      </div>
      <div className="filters">
        <input
          value={globalFilter || ''}
          onChange={e => setGlobalFilter(e.target.value || undefined)}
          placeholder="Search..."
          className="search-input"
        />
        <form onSubmit={handleSubmit}>
          <label>
            <select
              value={`${year}-${month}`}
              onChange={(e) => {
                const [selectedYear, selectedMonth] = e.target.value.split('-');
                setYear(Number(selectedYear));
                setMonth(Number(selectedMonth));
              }}
              className="select-input"
            >
              {yearMonths.map((item, index) => (
                <option key={index} value={`${item.year}-${item.month}`}>
                  {item.year}-{String(item.month).padStart(2, '0')}
                </option>
              ))}
            </select>
          </label>
        </form>
      </div>
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span style={{ marginLeft: '10px' }}>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <i className="fas fa-sort-down"></i>
                        : <i className="fas fa-sort-up"></i>
                      : null}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination">
        <button
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          className="pagination-button"
        >
          <FaAngleDoubleLeft />
        </button>
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="pagination-button"
        >
          <FaAngleLeft />
        </button>
        <span className="pagination-info">
          {pageIndex + 1} / {pageOptions.length}
        </span>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="pagination-button"
        >
          <FaAngleRight />
        </button>
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          className="pagination-button"
        >
          <FaAngleDoubleRight />
        </button>
      </div>
    </div>
  );
};

export default SalariesByYearMonth;
