import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaEdit, FaTrashAlt, FaTimes, FaSave, FaPlus, FaDownload, FaArrowLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { getContracts, createContract, updateContract, deleteContract, checkAdmin } from '../services/api';
import swal from 'sweetalert';
import { Link, useNavigate } from 'react-router-dom';

const AdminContractList = () => {
  const [contracts, setContracts] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentContract, setCurrentContract] = useState(null);
  const [formValues, setFormValues] = useState({
    employee_gmail: '',
    title: '',
    start_date: '',
    end_date: '',
    contract: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const response = await checkAdmin();
        const isAdmin = response.data.is_admin;
        if (!isAdmin) {
          navigate('/'); // 如果不是管理員，重定向到首頁
          return;
        }
        fetchData(); // 如果是管理員，則獲取數據
      } catch (error) {
        console.error('Error checking admin status:', error);
        navigate('/'); // 如果出錯，也重定向到首頁
      }
    };

    checkAdminStatus();
  }, [navigate]);

  const fetchData = useCallback(() => {
    getContracts().then(response => {
      setContracts(response.data);

      // 從合約數據中提取唯一的員工名稱
      const uniqueEmployees = [...new Set(response.data.map(contract => contract.employee_name))];
      setEmployeeOptions(uniqueEmployees.map(name => ({ value: name, label: name })));
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAdd = () => {
    setCurrentContract(null);
    setIsEditing(false);
    setFormValues({
      employee_gmail: '',
      title: '',
      start_date: '',
      end_date: '',
      contract: null,
    });
    setIsFormVisible(true);
  };

  const handleEdit = useCallback((contract) => {
    setCurrentContract(contract);
    setIsEditing(true);
    setFormValues({
      employee_gmail: contract.employee_gmail,
      title: contract.title,
      start_date: contract.start_date,
      end_date: contract.end_date,
      contract: null,
    });
    setIsFormVisible(true);
  }, []);

  const handleDelete = useCallback((contract) => {
    swal({
      title: "Delete Confirmation",
      text: `Are you sure you want to delete this contract?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteContract(contract.id).then(() => {
            fetchData();
            swal("Contract deleted successfully!", {
              icon: "success",
            });
          });
        }
      });
  }, [fetchData]);

  const columns = useMemo(() => [
    {
      Header: 'Index',
      accessor: (row, i) => i + 1,
    },
    {
      Header: '員工姓名',
      accessor: 'employee_name',
    },
    {
      Header: '合約標題',
      accessor: 'title',
    },
    {
      Header: '上傳日期',
      accessor: 'created_at',
      Cell: ({ value }) => formatToTaiwanDate(value),
    },
    {
      Header: '開始日期',
      accessor: 'start_date',
      Cell: ({ value }) => formatToTaiwanDate(value),
    },
    {
      Header: '結束日期',
      accessor: 'end_date',
      Cell: ({ value }) => formatToTaiwanDate(value),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="action-buttons">
          <a
            href={row.original.contract_url || '#'}
            target="_blank"
            rel="noopener noreferrer"
            className={row.original.contract_url ? '' : 'disabled'}
          >
            <FaDownload
              className={`icon download-icon ${row.original.contract_url ? '' : 'disabled'}`}
              title={row.original.contract_url ? "下載合約" : "無文件"}
            />
          </a>
          <FaTrashAlt
            className="icon delete-icon"
            onClick={() => handleDelete(row.original)}
            title="刪除合約"
          />
        </div>
      ),
    }
  ], [handleDelete, handleEdit]);

  const filteredContracts = useMemo(() => {
    return contracts.filter(contract => {
      const employeeMatch = selectedEmployees.length === 0 || selectedEmployees.some(e => e.value === contract.employee_name);
      const dateMatch = (!startDate || new Date(contract.start_date) >= startDate) &&
        (!endDate || new Date(contract.end_date) <= endDate);
      const globalFilterMatch = !filterInput ||
        Object.values(contract).some(value =>
          String(value).toLowerCase().includes(filterInput.toLowerCase())
        );
      return employeeMatch && dateMatch && globalFilterMatch;
    });
  }, [contracts, selectedEmployees, startDate, endDate, filterInput]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns,
      data: filteredContracts,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex } = state;

  const formatToTaiwanDate = (date) => {
    if (!date) return '';
    const options = {
      timeZone: 'Asia/Taipei',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formatter = new Intl.DateTimeFormat('zh-TW', options);
    return formatter.format(new Date(date));
  };

  const formatDateForBackend = (date) => {
    if (!date) return '';
    const d = new Date(date);
    return d.toISOString().split('T')[0];
  };

  const getCurrentDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  const handleDateChange = (e, name) => {
    const value = e.target.value;
    setFormValues(prevValues => {
      if (name === 'start_date') {
        if (prevValues.end_date && value > prevValues.end_date) {
          return { ...prevValues, [name]: value, end_date: value };
        }
      }
      return { ...prevValues, [name]: value };
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const form = event.target;
    const data = new FormData();

    data.append('employee_gmail', formValues.employee_gmail);
    data.append('title', formValues.title);
    data.append('start_date', formatDateForBackend(formValues.start_date));
    data.append('end_date', formatDateForBackend(formValues.end_date));
    if (formValues.contract) {
      data.append('contract', formValues.contract);
    }

    const apiCall = isEditing
      ? updateContract(currentContract.id, data)
      : createContract(data);

    apiCall
      .then(() => {
        fetchData();
        setIsFormVisible(false);
        if (isEditing) {
          setIsEditing(false);
          setCurrentContract(null);
        }
        swal("成功", isEditing ? "合約已更新" : "合約已創建", "success");
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
        swal("錯誤", "提交失敗，請稍後再試", "error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleClose = () => {
    setIsFormVisible(false);
  };

  return (
    <div className="container theme-yellow">
      <div className="header">
        <Link to="/dashboard" className="back-button" title="返回 Dashboard">
          <FaArrowLeft />
        </Link>
        <h1>合約管理</h1>
      </div>
      {isFormVisible && <div className="overlay visible" onClick={handleClose}></div>}
      <div className={`modal ${isFormVisible ? 'visible' : ''}`}>
        <div className="form-container">
          <h2>{isEditing ? '編輯合約' : '新增合約'}</h2>
          <form onSubmit={handleFormSubmit} className="contract-form">
            <div className="form-group">
              <label>
                員工郵箱: <span className="required">*</span>
                <input
                  type="email"
                  name="employee_gmail"
                  value={formValues.employee_gmail}
                  onChange={(e) => setFormValues({ ...formValues, employee_gmail: e.target.value })}
                  required
                />
              </label>
              <label>
                合約標題: <span className="required">*</span>
                <input
                  type="text"
                  name="title"
                  value={formValues.title}
                  onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                  required
                />
              </label>
              <label>
                開始日期: <span className="required">*</span>
                <input
                  type="date"
                  name="start_date"
                  value={formValues.start_date}
                  onChange={(e) => handleDateChange(e, 'start_date')}
                  required
                />
              </label>
              <label>
                結束日期: <span className="required">*</span>
                <input
                  type="date"
                  name="end_date"
                  value={formValues.end_date}
                  onChange={(e) => handleDateChange(e, 'end_date')}
                  min={formValues.start_date}
                  required
                />
              </label>
              <label>
                合約文件:
                <input
                  type="file"
                  name="contract"
                  accept=".pdf"
                  onChange={(e) => setFormValues({ ...formValues, contract: e.target.files[0] })}
                />
              </label>
            </div>
            <div className="form-actions">
              <button type="button" className="icon-button cancel-button" onClick={handleClose} disabled={isSubmitting}>
                <FaTimes className="icon" /> 取消
              </button>
              <button type="submit" className="icon-button save-button" disabled={isSubmitting}>
                <FaSave className="icon" /> {isSubmitting ? '保存中...' : '保存'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <div className="filters">
          <input
            value={filterInput || ''}
            onChange={e => setFilterInput(e.target.value || undefined)}
            placeholder="搜尋..."
          />
          <Select
            value={selectedEmployees}
            onChange={setSelectedEmployees}
            options={employeeOptions}
            placeholder="選擇員工"
            isMulti
          />
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="開始日期"
            isClearable={true}
          />
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="結束日期"
            isClearable={true}
          />
          <button className="add-button" onClick={handleAdd}>
            <FaPlus className="icon" /> 新增合約
          </button>
        </div>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <i className="fas fa-sort-down"></i>
                          : <i className="fas fa-sort-up"></i>
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="pagination-button"
          >
            <FaAngleDoubleLeft />
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="pagination-button"
          >
            <FaAngleLeft />
          </button>
          <span className="pagination-info">
            {pageIndex + 1} / {pageOptions.length}
          </span>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="pagination-button"
          >
            <FaAngleRight />
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="pagination-button"
          >
            <FaAngleDoubleRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminContractList;