import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FaMoneyBillWave, FaFileInvoiceDollar, FaFileContract, FaClock, FaCalendarAlt, FaUsers } from 'react-icons/fa';
import './Dashboard.css';

const Dashboard = () => {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const adminStatus = localStorage.getItem('is_admin');
        setIsAdmin(adminStatus === 'true');
    }, []);

    const adminCards = [
        { title: '員工管理', icon: <FaUsers />, link: '/admin/employees', description: '查看和管理員工資訊', color: '#5054cd' },
        { title: '薪資管理', icon: <FaMoneyBillWave />, link: '/admin/salaries', description: '查看和管理員工薪資', color: '#51C283' },
        { title: '請款管理', icon: <FaFileInvoiceDollar />, link: '/admin/reimbursements', description: '處理員工的請款申請', color: '#FF7043' },
        { title: '合約管理', icon: <FaFileContract />, link: '/admin/contracts', description: '管理員工合約', color: '#f0ad4e' },
        { title: '加班管理', icon: <FaClock />, link: '/admin/overtimes', description: '審核和管理加班申請', color: '#e74c3c' },
        { title: '請假管理', icon: <FaCalendarAlt />, link: '/admin/attendances', description: '處理員工的請假申請', color: '#009688' },
    ];

    const employeeCards = [
        { title: '請假紀錄', icon: <FaCalendarAlt />, link: '/employee/attendances', description: '查看和申請請假', color: '#009688' },
        { title: '加班紀錄', icon: <FaClock />, link: '/employee/overtimes', description: '查看和申請加班', color: '#e74c3c' },
        { title: '合約紀錄', icon: <FaFileContract />, link: '/employee/contracts', description: '查看個人合約', color: '#f0ad4e' },
        { title: '請款紀錄', icon: <FaFileInvoiceDollar />, link: '/employee/reimbursements', description: '查看和申請請款', color: '#FF7043' },
    ];

    const cards = isAdmin ? adminCards : employeeCards;

    return (
        <div className={`dashboard ${isAdmin ? 'admin' : 'employee'}`}>
            <h1 className="dashboard-title">WisdomeAI HR 管理系統</h1>
            <div className="card-container">
                {cards.map((card) => (
                    <Link to={card.link} key={card.title} className="card" style={{ backgroundColor: card.color }}>
                        <div className="card-content">
                            <div className="card-icon">{card.icon}</div>
                            <h2>{card.title}</h2>
                            <p>{card.description}</p>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default Dashboard;