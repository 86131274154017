export const generateSalaryHTML = (salary) => {
    const formatCurrency = (value) => {
        if (typeof value !== 'number') {
            return '0';
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return `
    <!DOCTYPE html>
    <html lang="zh-Hant">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <title>員工薪資表</title>
        <style>
            .salary-table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 1em;
            }

            .salary-table th,
            .salary-table td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
                position: relative;
            }

            .salary-table th {
                background-color: #3c40c6;
                color: white;
            }

            .salary-header {
                text-align: center;
                padding: 10px 0;
            }

            .salary-section {
                margin-bottom: 30px;
            }

            .total-row .total-cell {
                font-weight: bold;
                position: relative;
                padding-top: 12px;
                padding-bottom: 12px;
            }

            .total-row .total-cell::before,
            .total-row .total-cell::after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                right: 0;
            }

            .total-row .total-cell::before {
                top: 0;
                border-bottom: 1px solid #000;
            }

            .total-row .total-cell::after {
                bottom: 0;
                border-bottom: 3px double #000;
            }

            .flex-container {
                display: flex;
                justify-content: space-between;
                gap: 20px;
            }

            .flex-item {
                flex: 1;
            }

            .centered-title {
                text-align: center;
            }

        </style>
    </head>
    <body>
        <main class="container">
            <div class="salary-header">
                <h1>聚愢科技股份有限公司</h1>
                <h2>員工薪資表</h2>
            </div>
            <div class="salary-section">
                <table class="salary-table">
                    <thead>
                        <tr>
                            <th>員工姓名</th>
                            <th>職稱</th>
                            <th>部門</th>
                            <th>工資期間</th>
                            <th>工資發放日期</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>${salary.employee_name}</td>
                            <td>${salary.employee_position}</td>
                            <td>${salary.employee_department}</td>
                            <td>${salary.period}</td>
                            <td>${salary.payment_date}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="flex-container">
                <div class="flex-item">
                    <div class="salary-section">
                        <h2 class="centered-title">應發金額</h2>
                        <table class="salary-table">
                            <thead>
                                <tr>
                                    <th>項目</th>
                                    <th>金額</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td>基本工資</td><td>NT$ ${formatCurrency(salary.base_salary)}</td></tr>
                                <tr><td>加班工資</td><td>NT$ ${formatCurrency(salary.overtime_pay)}</td></tr>
                                <tr><td>獎金 - 績效獎金</td><td>NT$ ${formatCurrency(salary.performance_bonus)}</td></tr>
                                <tr><td>獎金 - 年終獎金</td><td>NT$ ${formatCurrency(salary.year_end_bonus)}</td></tr>
                                <tr><td>請款 - 報銷總額</td><td>NT$ ${formatCurrency(salary.total_reimbursement)}</td></tr>
                                <tr class="total-row"><td class="total-cell"><strong>應發總金額</strong></td><td class="total-cell"><strong>NT$ ${formatCurrency(salary.total_receivables)}</strong></td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="flex-item">
                    <div class="salary-section">
                        <h2 class="centered-title">應扣金額</h2>
                        <table class="salary-table">
                            <thead>
                                <tr>
                                    <th>項目</th>
                                    <th>金額</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr><td>個人勞保給付</td><td>NT$ ${formatCurrency(salary.employee_labor_insurance)}</td></tr>
                                <tr><td>個人健保給付</td><td>NT$ ${formatCurrency(salary.employee_health_insurance)}</td></tr>
                                <tr><td>個人所得稅提交</td><td>NT$ ${formatCurrency(salary.personal_income_tax_submission)}</td></tr>
                                <tr><td>事假扣款</td><td>NT$ ${formatCurrency(salary.personal_leave_deduction)}</td></tr>
                                <tr><td>病假扣款</td><td>NT$ ${formatCurrency(salary.sick_leave_deduction)}</td></tr>
                                <tr class="total-row"><td class="total-cell"><strong>應扣總金額</strong></td><td class="total-cell"><strong>NT$ ${formatCurrency(salary.total_deductions)}</strong></td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="salary-section">
                <h2 class="centered-title">實發金額</h2>
                <table class="salary-table">
                    <thead>
                        <tr><th>項目</th><th>金額</th></tr>
                    </thead>
                    <tbody>
                        <tr><td>應發總金額</td><td>NT$ ${formatCurrency(salary.total_receivables)}</td></tr>
                        <tr><td>應扣總金額</td><td>NT$ ${formatCurrency(salary.total_deductions)}</td></tr>
                        <tr class="total-row"><td class="total-cell"><strong>實發金額</strong></td><td class="total-cell"><strong>NT$ ${formatCurrency(salary.total_salary)}</strong></td></tr>
                    </tbody>
                </table>
            </div>
        </main>
    </body>
    </html>
    `;
};
