import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaInfoCircle, FaClock, FaCheck, FaTimesCircle, FaEdit, FaTrashAlt, FaTimes, FaSave, FaPlus, FaArrowLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { getOvertimes, getOvertimesByYearMonth, createOvertime, updateOvertime, deleteOvertime } from '../services/api';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { format, eachMonthOfInterval, startOfMonth, endOfMonth } from 'date-fns';

const OverTimeList = () => {
  const [overtimes, setOvertimes] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentOvertime, setCurrentOvertime] = useState(null);
  const [formValues, setFormValues] = useState({
    date: '',
    start_time: '',
    end_time: '',
    description: '',
    status: 'Pending',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [overtimeTypeOptions, setOvertimeTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedOvertimeType, setSelectedOvertimeType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedYearMonth, setSelectedYearMonth] = useState(null);

  const STATUS_MAP = {
    'Pending': '待審核',
    'Approved': '已批准',
    'Rejected': '已拒絕'
  };

  const STATUS_OPTIONS = [
    { value: 'Pending', label: '待審核' },
    { value: 'Approved', label: '已批准' },
    { value: 'Rejected', label: '已拒絕' }
  ];

  const currentYearMonth = useMemo(() => {
    const now = new Date();
    return {
      value: format(now, 'yyyy-MM'),
      label: format(now, 'yyyy-MM')
    };
  }, []);

  const yearMonthOptions = useMemo(() => {
    const startDate = new Date(2024, 7, 1); // 2024-07-01
    const endDate = endOfMonth(new Date()); // Current month's end
    const months = eachMonthOfInterval({ start: startDate, end: endDate });
    const options = months.map(date => ({
      value: format(date, 'yyyy-MM'),
      label: format(date, 'yyyy-MM')
    })).reverse(); // Reverse to show most recent first
    
    // Add "All" option
    return [{ value: 'all', label: '全部時間' }, ...options];
  }, []);

  useEffect(() => {
    setSelectedYearMonth(currentYearMonth);
  }, [currentYearMonth]);

  const fetchData = useCallback(() => {
    if (selectedYearMonth && selectedYearMonth.value !== 'all') {
      const [year, month] = selectedYearMonth.value.split('-');
      getOvertimesByYearMonth(year, month).then(response => {
        setOvertimes(response.data);
      });
    } else {
      getOvertimes().then(response => {
        setOvertimes(response.data);
      });
    }
  }, [selectedYearMonth]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  useEffect(() => {
    if (currentOvertime) {
      setFormValues({
        date: formatDateForInput(currentOvertime.date),
        start_time: currentOvertime.start_time || '',
        end_time: currentOvertime.end_time || '',
        description: currentOvertime.description || '',
        status: currentOvertime.status || 'Pending',
      });
    } else {
      setFormValues({
        date: '',
        start_time: '',
        end_time: '',
        description: '',
        status: 'Pending',
      });
    }
  }, [currentOvertime]);

  const handleAdd = () => {
    setCurrentOvertime(null);
    setIsEditing(false);
    setFormValues({
      date: '',
      start_time: '',
      end_time: '',
      description: '',
      status: 'Pending',
    });
    setIsFormVisible(true);
  };

  const handleEdit = useCallback((overtime) => {
    setCurrentOvertime(overtime);
    setIsEditing(true);
    setIsFormVisible(true);
  }, []);

  const handleDelete = useCallback((overtime) => {
    const today = new Date();
    const overtimeDate = new Date(overtime.date);
    const isPastMonth = 
      overtimeDate.getFullYear() < today.getFullYear() ||
      (overtimeDate.getFullYear() === today.getFullYear() && 
       overtimeDate.getMonth() < today.getMonth());

    if (isPastMonth) {
      swal("Error", "無法刪除過去月份的加班記錄", "error");
      return;
    }

    swal({
      title: "Delete Confirmation",
      text: `Are you sure you want to delete this overtime record?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          deleteOvertime(overtime.id).then(() => {
            fetchData();
            swal("Overtime record deleted successfully!", {
              icon: "success",
            });
          });
        }
      });
  }, [fetchData]);

  const formatTime = (time) => {
    if (!time) return '';
    return time.substring(0, 5);
  };

  const columns = useMemo(() => [
    {
      Header: 'Index',
      accessor: (row, i) => i + 1,
    },
    {
      Header: '加班類型',
      accessor: 'overtime_type',
      Cell: ({ value, row }) => {
        const description = row.original.description;
        if (!description || description.trim() === '') {
          return value;
        }
        return (
          <div className="tooltip leave-type-container">
            {value}
            <FaInfoCircle className="info-icon" />
            <span className="tooltiptext">
              {description}
            </span>
          </div>
        );
      },
    },
    {
      Header: '申請時間',
      accessor: 'created_at',
      Cell: ({ value }) => formatDateTime(value),
    },
    {
      Header: '加班日期',
      accessor: 'date',
      Cell: ({ value }) => formatToTaiwanDate(value),
  },
  {
    Header: '加班時間',
    accessor: row => ({ start: row.start_time, end: row.end_time }),
    Cell: ({ value }) => (
        <span>
            {formatTime(value.start)} ~ {formatTime(value.end)}
        </span>
    ),
},
    {
      Header: '加班時數',
      accessor: 'hours',
    },
    {
      Header: '加班狀態',
      accessor: 'status',
      Cell: ({ value }) => (
        <span className={`status-tag status-${value.toLowerCase()}`}>
          {value === 'Pending' && <><FaClock className="icon-space" /> 待審核</>}
          {value === 'Approved' && <><FaCheck className="icon-space" /> 已批准</>}
          {value === 'Rejected' && <><FaTimesCircle className="icon-space" /> 已拒絕</>}
        </span>
      ),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => {
        const overtime = row.original;
        const today = new Date();
        const overtimeDate = new Date(overtime.date);
        const isPastMonth = 
          overtimeDate.getFullYear() < today.getFullYear() ||
          (overtimeDate.getFullYear() === today.getFullYear() && 
           overtimeDate.getMonth() < today.getMonth());
        const isPending = overtime.status === 'Pending';

        return (
          <div className="action-buttons">
            <FaEdit
              onClick={() => isPending && !isPastMonth && handleEdit(overtime)}
              className={`icon edit-icon ${(!isPending || isPastMonth) ? 'disabled' : ''}`}
            />
            <FaTrashAlt
              onClick={() => isPending && !isPastMonth && handleDelete(overtime)}
              className={`icon delete-icon ${(!isPending || isPastMonth) ? 'disabled' : ''}`}
            />
          </div>
        );
      },
    }
  ], [handleDelete, handleEdit]);

  const filteredOvertimes = useMemo(() => {
    return overtimes.filter(overtime => {
      const overtimeTypeMatch = !selectedOvertimeType || selectedOvertimeType.length === 0 || 
        selectedOvertimeType.some(option => option.value === overtime.overtime_type);
      const statusMatch = !selectedStatus || selectedStatus.length === 0 || 
        selectedStatus.some(option => option.value === overtime.status);
      const dateMatch = (!startDate || new Date(overtime.date) >= startDate) &&
        (!endDate || new Date(overtime.date) <= endDate);
      return overtimeTypeMatch && statusMatch && dateMatch;
    });
  }, [overtimes, selectedOvertimeType, selectedStatus, startDate, endDate]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns,
      data: filteredOvertimes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex } = state;

  const formatDateTime = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleString('zh-TW', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).replace(/\//g, '-');
  };

  const formatToTaiwanDate = (date) => {
    if (!date) return '';
    const options = {
      timeZone: 'Asia/Taipei',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formatter = new Intl.DateTimeFormat('zh-TW', options);
    return formatter.format(new Date(date));
  };

  const getCurrentMonthRange = () => {
    const now = new Date();
    const firstDay = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
    const lastDay = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 0));
    return {
      min: firstDay.toISOString().split('T')[0],
      max: lastDay.toISOString().split('T')[0]
    };
  };
  

  const handleDateChange = (e, name) => {
    let value = e.target.value;
    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const validateTimes = () => {
    const { start_time, end_time } = formValues;
    console.log('Start time:', start_time);
    console.log('End time:', end_time);
    if (start_time >= end_time) {
      swal("Error", "開始時間必須早於結束時間", "error");
      return false;
    }
    return true;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!validateTimes()) return;

    setIsSubmitting(true);

    const form = event.target;
    const data = new FormData();

    data.append('date', form.date?.value ? new Date(form.date.value).toISOString().split('T')[0] : null);
    data.append('start_time', formatTime(formValues.start_time));
    data.append('end_time', formatTime(formValues.end_time));
    data.append('description', form.description?.value || '');
    data.append('status', 'Pending');

    const apiCall = isEditing
      ? updateOvertime(currentOvertime.id, data)
      : createOvertime(data);

    apiCall
      .then(() => {
        fetchData();
        setIsFormVisible(false);
        if (isEditing) {
          setIsEditing(false);
          setCurrentOvertime(null);
        }
        swal("成功", isEditing ? "加班記錄已更新" : "加班申請已提交", "success");
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
        if (error.response && error.response.status === 404) {
          swal("申請失敗", "加班日期必須在當前月份內", "error");
        } else {
          swal("錯誤", "提交失敗，請稍後再試", "error");
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleClose = () => {
    setIsFormVisible(false);
  };

  useEffect(() => {
    const selectElement = document.querySelector('.status-select');
    if (selectElement) {
      selectElement.className = `status-select status-${formValues.status.toLowerCase()}`;
    }
  }, [formValues.status]);

  useEffect(() => {
    const uniqueOvertimeTypes = [...new Set(overtimes.map(ot => ot.overtime_type))];
    setOvertimeTypeOptions(uniqueOvertimeTypes.map(type => ({ value: type, label: type })));
    setStatusOptions(STATUS_OPTIONS);
  }, [overtimes]);

  const generateTitle = useMemo(() => {
    if (!selectedYearMonth || selectedYearMonth.value === 'all') {
      return '加班紀錄 (全部時間)';
    }
    const [year, month] = selectedYearMonth.value.split('-');
    return `加班紀錄 (${year}年${month}月)`;
  }, [selectedYearMonth]);

  return (
    <div className="container theme-red">
      <div className="header">
        <Link to="/dashboard" className="back-button" title="返回 Dashboard">
          <FaArrowLeft />
        </Link>
        <h1>{generateTitle}</h1>
      </div>
      {isFormVisible && <div className="overlay visible" onClick={handleClose}></div>}
      <div className={`modal ${isFormVisible ? 'visible' : ''}`}>
        <div className="form-container">
          <h2>{isEditing ? '編輯加班' : '申請加班'}</h2>
          <form onSubmit={handleFormSubmit} className="overtime-form">
            <div className="form-group">
              <label>
                加班日期: <span className="required">*</span>
                <input
                  type="date"
                  name="date"
                  value={formValues.date}
                  onChange={(e) => handleDateChange(e, 'date')}
                  min={getCurrentMonthRange().min}
                  max={getCurrentMonthRange().max}
                  required
                />
              </label>
              <label>
                加班原因:
                <textarea name="description" value={formValues.description} onChange={(e) => setFormValues({ ...formValues, description: e.target.value })} />
              </label>
              <label>
                開始時間: <span className="required">*</span>
                <input
                  type="time"
                  name="start_time"
                  value={formValues.start_time}
                  onChange={(e) => setFormValues({ ...formValues, start_time: e.target.value })}
                  required
                />
              </label>
              <label>
                結束時間: <span className="required">*</span>
                <input
                  type="time"
                  name="end_time"
                  value={formValues.end_time}
                  onChange={(e) => setFormValues({ ...formValues, end_time: e.target.value })}
                  required
                />
              </label>
             
            </div>
            <div className="form-actions">
              <button type="button" className="icon-button cancel-button" onClick={handleClose} disabled={isSubmitting}>
                <FaTimes className="icon" /> 取消
              </button>
              <button type="submit" className="icon-button save-button" disabled={isSubmitting}>
                <FaSave className="icon" /> {isSubmitting ? '儲存中...' : '儲存'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <div className="filters">
          <input
            value={globalFilter || ''}
            onChange={e => setGlobalFilter(e.target.value || undefined)}
            placeholder="搜尋..."
          />
          <Select
            options={yearMonthOptions}
            value={selectedYearMonth}
            onChange={(option) => setSelectedYearMonth(option)}
            placeholder="選擇年月"
          />
          <Select
            options={overtimeTypeOptions}
            value={selectedOvertimeType}
            onChange={options => setSelectedOvertimeType(options)}
            placeholder="選擇加班類型"
            isMulti
          />
          <Select
            options={statusOptions}
            value={selectedStatus}
            onChange={options => setSelectedStatus(options)}
            placeholder="選擇狀態"
            isMulti
          />
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="開始日期"
            isClearable={true}
          />
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="結束日期"
            isClearable={true}
          />
          <button className="add-button" onClick={handleAdd}>
            <FaPlus className="icon" /> 申請加班
          </button>
        </div>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span style={{ marginLeft: '10px' }}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <i className="fas fa-sort-down"></i>
                          : <i className="fas fa-sort-up"></i>
                        : null}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button 
            onClick={() => gotoPage(0)} 
            disabled={!canPreviousPage}
            className="pagination-button"
          >
            <FaAngleDoubleLeft />
          </button>
          <button 
            onClick={() => previousPage()} 
            disabled={!canPreviousPage}
            className="pagination-button"
          >
            <FaAngleLeft />
          </button>
          <span className="pagination-info">
            {pageIndex + 1} / {pageOptions.length}
          </span>
          <button 
            onClick={() => nextPage()} 
            disabled={!canNextPage}
            className="pagination-button"
          >
            <FaAngleRight />
          </button>
          <button 
            onClick={() => gotoPage(pageCount - 1)} 
            disabled={!canNextPage}
            className="pagination-button"
          >
            <FaAngleDoubleRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default OverTimeList;