import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaInfoCircle, FaPlus, FaEdit, FaTrashAlt, FaSave, FaTimes, FaClock, FaCheck, FaTimesCircle, FaDownload, FaArrowLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa';
import { getReimbursements, getReimbursementsByYearMonth, createReimbursement, updateReimbursement, deleteReimbursement } from '../services/api';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { format, eachMonthOfInterval, startOfMonth, endOfMonth } from 'date-fns';

const Reimbursement = () => {
  const [reimbursements, setReimbursements] = useState([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentReimbursement, setCurrentReimbursement] = useState(null);
  const [formValues, setFormValues] = useState({
    reimbursement_type: '',
    amount: '',
    description: '',
    start_date: '',
    end_date: '',
    receipt: null,
    status: 'Pending',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reimbursementTypeOptions, setReimbursementTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedReimbursementType, setSelectedReimbursementType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedYearMonth, setSelectedYearMonth] = useState(null);

  const REIMBURSEMENT_TYPES = [
    { value: '交通費', label: '交通費' },
    { value: '餐費', label: '餐費' },
    { value: '住宿費', label: '住宿費' },
    { value: '辦公用品', label: '辦公用品' },
    { value: '其他', label: '其他' },
  ];

  const STATUS_MAP = {
    'Pending': '待審核',
    'Approved': '已批准',
    'Rejected': '已拒絕'
  };

  const STATUS_OPTIONS = [
    { value: 'Pending', label: '待審核' },
    { value: 'Approved', label: '已批准' },
    { value: 'Rejected', label: '已拒絕' }
  ];

  const currentYearMonth = useMemo(() => {
    const now = new Date();
    return {
      value: format(now, 'yyyy-MM'),
      label: format(now, 'yyyy-MM')
    };
  }, []);

  const yearMonthOptions = useMemo(() => {
    const startDate = new Date(2024, 7, 1); // 2024-07-01
    const endDate = endOfMonth(new Date()); // Current month's end
    const months = eachMonthOfInterval({ start: startDate, end: endDate });
    const options = months.map(date => ({
      value: format(date, 'yyyy-MM'),
      label: format(date, 'yyyy-MM')
    })).reverse(); // Reverse to show most recent first
    
    // Add "All" option
    return [{ value: 'all', label: '全部時間' }, ...options];
  }, []);

  useEffect(() => {
    setSelectedYearMonth(currentYearMonth);
  }, [currentYearMonth]);

  const fetchData = useCallback(() => {
    if (selectedYearMonth && selectedYearMonth.value !== 'all') {
      const [year, month] = selectedYearMonth.value.split('-');
      getReimbursementsByYearMonth(year, month).then(response => {
        setReimbursements(response.data);
        // ... (rest of the code to set options)
      });
    } else {
      getReimbursements().then(response => {
        setReimbursements(response.data);
        // ... (rest of the code to set options)
      });
    }
  }, [selectedYearMonth]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (currentReimbursement) {
      setFormValues({
        reimbursement_type: currentReimbursement.reimbursement_type || '',
        amount: currentReimbursement.amount || '',
        description: currentReimbursement.description || '',
        start_date: currentReimbursement.start_date ? formatDateForInput(currentReimbursement.start_date) : '',
        end_date: currentReimbursement.end_date ? formatDateForInput(currentReimbursement.end_date) : '',
        receipt: currentReimbursement.receipt_url || null,
        status: currentReimbursement.status || 'Pending',
      });
    } else {
      setFormValues({
        reimbursement_type: '',
        amount: '',
        description: '',
        start_date: '',
        end_date: '',
        receipt: null,
        status: 'Pending',
      });
    }
  }, [currentReimbursement]);

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const handleAdd = () => {
    setCurrentReimbursement(null);
    setIsEditing(false);
    setFormValues({
      reimbursement_type: '',
      amount: '',
      description: '',
      start_date: '',
      end_date: '',
      receipt: null,
      status: 'Pending',
    });
    setIsFormVisible(true);
  };

  const handleEdit = useCallback((reimbursement) => {
    const today = new Date();
    const reimbursementDate = new Date(reimbursement.start_date);
    const isPastMonth = 
      reimbursementDate.getFullYear() < today.getFullYear() ||
      (reimbursementDate.getFullYear() === today.getFullYear() && 
       reimbursementDate.getMonth() < today.getMonth());

    if (isPastMonth) {
      swal("錯誤", "無法編輯過去月份的請款記錄", "error");
      return;
    }

    if (reimbursement.status !== 'Pending') {
      swal("錯誤", "只能編輯待審核的請款記錄", "error");
      return;
    }

    setCurrentReimbursement(reimbursement);
    setIsEditing(true);
    setIsFormVisible(true);
  }, []);

  const handleDelete = useCallback((reimbursement) => {
    const today = new Date();
    const reimbursementDate = new Date(reimbursement.start_date);
    const isPastMonth = 
      reimbursementDate.getFullYear() < today.getFullYear() ||
      (reimbursementDate.getFullYear() === today.getFullYear() && 
       reimbursementDate.getMonth() < today.getMonth());

    if (isPastMonth) {
      swal("錯誤", "無法刪除過去月份的請款記錄", "error");
      return;
    }

    if (reimbursement.status !== 'Pending') {
      swal("錯誤", "只能刪除待審核的請款記錄", "error");
      return;
    }

    swal({
      title: "確定要刪除嗎？",
      text: "刪除後將無法恢復此請款記錄！",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        deleteReimbursement(reimbursement.id).then(() => {
          fetchData();
          swal("刪除成功！", "請款記錄已被刪除。", "success");
        });
      }
    });
  }, [fetchData]);

  const columns = useMemo(() => [
    {
      Header: 'Index',
      accessor: (row, i) => i + 1,
    },
    {
      Header: '請款類型',
      accessor: 'reimbursement_type',
      Cell: ({ value, row }) => {
        const description = row.original.description;
        if (!description || description.trim() === '') {
          return value;
        }
        return (
          <div className="tooltip">
            {value}
            <FaInfoCircle className="info-icon" />
            <span className="tooltiptext">
              {description}
            </span>
          </div>
        );
      },
    },
    {
      Header: '申請時間',
      accessor: 'created_at',
      Cell: ({ value }) => formatDateTime(value),
    },
    {
      Header: '請款開始時間',
      accessor: 'start_date',
      Cell: ({ value }) => formatToTaiwanDate(value),
    },
    {
      Header: '請款結束時間',
      accessor: 'end_date',
      Cell: ({ value }) => formatToTaiwanDate(value),
    },
    {
      Header: '請款金額',
      accessor: 'amount',
    },
    {
      Header: '請款狀態',
      accessor: 'status',
      Cell: ({ value }) => (
        <span className={`status-tag status-${value.toLowerCase()}`}>
          {value === 'Pending' && <><FaClock className="icon-space" /> {STATUS_MAP[value]}</>}
          {value === 'Approved' && <><FaCheck className="icon-space" /> {STATUS_MAP[value]}</>}
          {value === 'Rejected' && <><FaTimesCircle className="icon-space" /> {STATUS_MAP[value]}</>}
        </span>
      ),
    },
    {
      Header: 'Actions',
      accessor: 'actions',
      Cell: ({ row }) => {
        const reimbursement = row.original;
        const today = new Date();
        const reimbursementDate = new Date(reimbursement.start_date);
        const isPastMonth = 
          reimbursementDate.getFullYear() < today.getFullYear() ||
          (reimbursementDate.getFullYear() === today.getFullYear() && 
           reimbursementDate.getMonth() < today.getMonth());
        const isPending = reimbursement.status === 'Pending';

        return (
          <div className="action-buttons">
            {reimbursement.receipt_url ? (
              <a href={reimbursement.receipt_url} target="_blank" rel="noopener noreferrer">
                <FaDownload className="icon download-icon available" />
              </a>
            ) : (
              <FaDownload className="icon download-icon disabled" />
            )}
            <FaEdit
              onClick={() => !isPastMonth && isPending && handleEdit(reimbursement)}
              className={`icon edit-icon ${(isPastMonth || !isPending) ? 'disabled' : ''}`}
              title={isPastMonth ? "無法編輯過去月份的記錄" : (!isPending ? "只能編輯待審核的記錄" : "編輯")}
            />
            <FaTrashAlt
              onClick={() => !isPastMonth && isPending && handleDelete(reimbursement)}
              className={`icon delete-icon ${(isPastMonth || !isPending) ? 'disabled' : ''}`}
              title={isPastMonth ? "無法刪除過去月份的記錄" : (!isPending ? "只能刪除待審核的記錄" : "刪除")}
            />
          </div>
        );
      },
    },
  ], [handleDelete, handleEdit]);

  const filteredReimbursements = useMemo(() => {
    return reimbursements.filter(reimbursement => {
      const typeMatch = !selectedReimbursementType || selectedReimbursementType.length === 0 || 
        selectedReimbursementType.some(option => option.value === reimbursement.reimbursement_type);
      const statusMatch = !selectedStatus || selectedStatus.length === 0 || 
        selectedStatus.some(option => option.value === reimbursement.status);
      const dateMatch = (!startDate || new Date(reimbursement.start_date) >= startDate) &&
        (!endDate || new Date(reimbursement.end_date) <= endDate);
      return typeMatch && statusMatch && dateMatch;
    });
  }, [reimbursements, selectedReimbursementType, selectedStatus, startDate, endDate]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
  } = useTable(
    {
      columns,
      data: filteredReimbursements,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex } = state;

  const formatDateTime = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleString('zh-TW', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    }).replace(/\//g, '-');
  };

  const formatToTaiwanDate = (date) => {
    if (!date) return '';
    const options = {
      timeZone: 'Asia/Taipei',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const formatter = new Intl.DateTimeFormat('zh-TW', options);
    return formatter.format(new Date(date));
  };

  const getCurrentDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  const handleDateChange = (e, name) => {
    let value = e.target.value;
    setFormValues(prevValues => {
      if (name === 'start_date') {
        if (prevValues.end_date && prevValues.end_date < value) {
          return { ...prevValues, [name]: value, end_date: value };
        }
      }
      return { ...prevValues, [name]: value };
    });
  };

  const validateDates = () => {
    const { start_date, end_date } = formValues;
    if (new Date(start_date) > new Date(end_date)) {
      swal("Error", "開始時間不能比結束時間晚", "error");
      return false;
    }
    return true;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (!validateDates()) return;

    setIsSubmitting(true);

    const form = event.target;
    const data = new FormData();

    data.append('reimbursement_type', formValues.reimbursement_type);
    data.append('amount', formValues.amount);
    data.append('description', formValues.description);
    data.append('start_date', form.start_date?.value ? new Date(form.start_date.value).toISOString() : null);
    data.append('end_date', form.end_date?.value ? new Date(form.end_date.value).toISOString() : null);
    if (form.receipt?.files[0]) {
      data.append('receipt', form.receipt.files[0]);
    }
    data.append('status', 'Pending');

    const apiCall = isEditing
      ? updateReimbursement(currentReimbursement.id, data)
      : createReimbursement(data);

    apiCall
      .then(() => {
        fetchData();
        setIsFormVisible(false);
        if (isEditing) {
          setIsEditing(false);
          setCurrentReimbursement(null);
        }
        swal("成功", isEditing ? "請款記錄已更新" : "請款申請已提交", "success");
      })
      .catch((error) => {
        console.error('Error submitting form:', error);
        swal("錯誤", "提交失敗，請稍後再試", "error");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleClose = () => {
    setIsFormVisible(false);
  };

  useEffect(() => {
    const uniqueReimbursementTypes = [...new Set(reimbursements.map(reimb => reimb.reimbursement_type))];
    setReimbursementTypeOptions(uniqueReimbursementTypes.map(type => ({ value: type, label: type })));
    setStatusOptions(STATUS_OPTIONS);
  }, [reimbursements]);

  const generateTitle = useMemo(() => {
    if (!selectedYearMonth || selectedYearMonth.value === 'all') {
      return '請款紀錄 (全部時間)';
    }
    const [year, month] = selectedYearMonth.value.split('-');
    return `請款紀錄 (${year}年${month}月)`;
  }, [selectedYearMonth]);

  return (
    <div className="container theme-orange">
      <div className="header">
        <Link to="/dashboard" className="back-button" title="返回 Dashboard">
          <FaArrowLeft />
        </Link>
        <h1>{generateTitle}</h1>
      </div>
      {isFormVisible && <div className="overlay visible" onClick={handleClose}></div>}
      <div className={`modal ${isFormVisible ? 'visible' : ''}`}>
        <div className="form-container">
          <h2>{isEditing ? '編輯請款' : '申請請款'}</h2>
          <form onSubmit={handleFormSubmit} className="reimbursement-form" encType="multipart/form-data">
            <div className="form-group">
              <label>
                請款類型: <span className="required">*</span>
                <Select
                  options={REIMBURSEMENT_TYPES}
                  value={REIMBURSEMENT_TYPES.find(option => option.value === formValues.reimbursement_type)}
                  onChange={option => setFormValues({ ...formValues, reimbursement_type: option.value })}
                  required
                />
              </label>
              <label>
                請款金額: <span className="required">*</span>
                <input
                  type="number"
                  name="amount"
                  value={formValues.amount}
                  onChange={(e) => setFormValues({ ...formValues, amount: e.target.value })}
                  required
                />
              </label>
              <label>
                請款開始時間: <span className="required">*</span>
                <input
                  type="date"
                  name="start_date"
                  value={formValues.start_date}
                  onChange={(e) => handleDateChange(e, 'start_date')}
                  required
                />
              </label>
              <label>
                請款結束時間: <span className="required">*</span>
                <input
                  type="date"
                  name="end_date"
                  value={formValues.end_date}
                  onChange={(e) => handleDateChange(e, 'end_date')}
                  min={formValues.start_date}
                />
              </label>
              <label>
                請款說明:
                <textarea 
                  name="description" 
                  value={formValues.description} 
                  onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
                />
              </label>
              <label>
                請款證明:
                <input
                  type="file"
                  name="receipt"
                  accept=".zip,.tar,.rar"
                  onChange={(e) => setFormValues(prev => ({ ...prev, receipt: e.target.files[0] }))}
                />
              </label>
            </div>
            <div className="form-actions">
              <button type="button" className="icon-button cancel-button" onClick={handleClose} disabled={isSubmitting}>
                <FaTimes className="icon" /> 取消
              </button>
              <button type="submit" className="icon-button save-button" disabled={isSubmitting}>
                <FaSave className="icon" /> {isSubmitting ? '儲存中...' : '儲存'}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <div className="filters">
          <input
            value={globalFilter || ''}
            onChange={e => setGlobalFilter(e.target.value || undefined)}
            placeholder="搜尋..."
          />
          <Select
            options={yearMonthOptions}
            value={selectedYearMonth}
            onChange={(option) => setSelectedYearMonth(option)}
            placeholder="選擇年月"
          />
          <Select
            options={reimbursementTypeOptions}
            value={selectedReimbursementType}
            onChange={options => setSelectedReimbursementType(options)}
            placeholder="選擇請款類型"
            isMulti
          />
          <Select
            options={statusOptions}
            value={selectedStatus}
            onChange={options => setSelectedStatus(options)}
            placeholder="選擇狀態"
            isMulti
          />
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="開始日期"
            isClearable={true}
          />
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="結束日期"
            isClearable={true}
          />
          <button className="add-button" onClick={handleAdd}>
            <FaPlus className="icon" /> 申請請款
          </button>
        </div>
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span style={{ marginLeft: '10px' }}>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <i className="fas fa-sort-down"></i>
                          : <i className="fas fa-sort-up"></i>
                        : null}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="pagination">
          <button 
            onClick={() => gotoPage(0)} 
            disabled={!canPreviousPage}
            className="pagination-button"
          >
            <FaAngleDoubleLeft />
          </button>
          <button 
            onClick={() => previousPage()} 
            disabled={!canPreviousPage}
            className="pagination-button"
          >
            <FaAngleLeft />
          </button>
          <span className="pagination-info">
            {pageIndex + 1} / {pageOptions.length}
          </span>
          <button 
            onClick={() => nextPage()} 
            disabled={!canNextPage}
            className="pagination-button"
          >
            <FaAngleRight />
          </button>
          <button 
            onClick={() => gotoPage(pageCount - 1)} 
            disabled={!canNextPage}
            className="pagination-button"
          >
            <FaAngleDoubleRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Reimbursement;